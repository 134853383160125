import { DEFAULT_MOBILE_APP_SETTINGS } from "../../lib/constants";
import { saveSavedFormInfoToStorage, setMobileAppSettings } from "../../lib/funcs";
import { SET_SAVED_FORM_INFO, SET_SETTINGS, VariableActionTypes } from "./varActionTypes";

const DEFAULT_STATE: IVarState = {
  settings: DEFAULT_MOBILE_APP_SETTINGS,
  savedFormInfo: {}
}

const varReducer = (state = DEFAULT_STATE, action: VariableActionTypes): IVarState => {
  switch (action.type) {
    case SET_SETTINGS: {
      const result = {
        ...state,
        settings: action.payload,
      };
      setMobileAppSettings(result.settings);
      return result;
    }
    case SET_SAVED_FORM_INFO: {
      const result = {
        ...state,
        savedFormInfo: action.payload,
      };
      saveSavedFormInfoToStorage(result.savedFormInfo);
      return result;
    }
    default:
      return state;
  }
};


export default varReducer;
