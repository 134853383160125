import React, { createContext, useContext, useState, ReactNode } from "react";

type LanguageData = {
  [key: string]: any;
};
type LanguageContextType = {
  languageData: LanguageData;
  setLanguageData: (data: LanguageData) => void;
};

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
);

export function LanguageProvider({ children }: { children: ReactNode }) {
  const [languageData, setLanguageData] = useState<LanguageData>({});

  return (
    <LanguageContext.Provider value={{ languageData, setLanguageData }}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
}
