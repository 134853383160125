import { ChangeEventHandler } from 'react';
import { getInstrumentBySymbolName, onlyNum, saveOnePip } from '../../../lib/funcs';
import InputGroup from '../InputGroup';
import { useLanguage } from '../../../lib/LanguageContext';
import { useDispatch } from 'react-redux';

interface IOnePip {
  updater: (updates: any) => any;
  onePip: string | number,
  selectedInstrument: string | undefined;
  className?: string;
}

function OnePip({ onePip, selectedInstrument, className, updater }: IOnePip) {
  const dispatch = useDispatch();
  const { languageData } = useLanguage();

  const onePipChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    const op = onlyNum(e.target.value);
    if(selectedInstrument)
      saveOnePip(selectedInstrument, op, dispatch);

    dispatch(updater({ onePip: op }));
  }

  return selectedInstrument ? (
      <div className={className || "col-12"}>
        <InputGroup type="text" hint={getInstrumentBySymbolName(selectedInstrument)?.Market === 'Crypto' ? languageData.this_may_vary_in_your_broker : '' } label={'1 ' + languageData.pips} value={onePip} onChange={onePipChanged} />
      </div>
    ) : <></>;
}

export default OnePip;
