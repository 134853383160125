import React from "react";
import LotSizeCalculator from "./components/LotSizeCalculator";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PipValueCalculator from "./components/PipValueCalculator";
import MarginCalculator from "./components/MarginCalculator";
import ProfitCalculator from "./components/ProfitCalculator";
import RRWinRateCalculator from "./components/RRWinRateCalculator";
import Home from "./components/Home";
import { LanguageProvider } from "./lib/LanguageContext";

function App() {
  return (
    <BrowserRouter>
      <LanguageProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/lotsize" element={<LotSizeCalculator />} />
          <Route path="/margin" element={<MarginCalculator />} />
          <Route path="/pipvalue" element={<PipValueCalculator />} />
          <Route path="/profit" element={<ProfitCalculator />} />
          <Route path="/rrwinrate" element={<RRWinRateCalculator />} />
        </Routes>
      </LanguageProvider>
    </BrowserRouter>
  );
}

export default App;
