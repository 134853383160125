import { useEffect, useState } from "react";
import { useRemainingUpdateSeconds } from "../../../lib/custom-hooks";
import { getLanguageDirection, updatePricesGlobally } from "../../../lib/funcs";
import styles from "./InnerPriceUpdater.module.scss";
import { useLanguage } from "../../../lib/LanguageContext";
import { useDispatch } from "react-redux";

function InnerPriceUpdater() {
  const { languageData } = useLanguage();
  const dispatch = useDispatch();

  const remainingSeconds: number = useRemainingUpdateSeconds();
  const [loading, setLoading] = useState(true);

  const updatePrices = () => {
    setLoading(true);
    updatePricesGlobally(dispatch).finally(() => setTimeout(() => setLoading(false), 900));
  };

  useEffect(() => {
    if(remainingSeconds > 0){
      setLoading(false);
      if(remainingSeconds === 20) 
        updatePricesGlobally(dispatch, true);
    }
  }, [remainingSeconds]);

  return (
    <div className={[styles.innerPriceUpdater, styles[getLanguageDirection(languageData)]].join(" ")}>
      {loading ? (
        <span>Updating...</span>
      ) : (
        <button
          onClick={updatePrices}
          disabled={remainingSeconds > 0}
          className="btn btn-link btn-primary"
        >
          {languageData.update_prices}
          {remainingSeconds > 0 ? ` (${remainingSeconds})` : ""}
        </button>
      )}
    </div>
  );
}

export default InnerPriceUpdater;
