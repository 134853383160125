import { InstrumentsActionTypes, DepositCurrenciesActionTypes, UPDATE_INSTRUMENTS, UPDATE_DEPOSIT_CURRENCIES } from "./actionTypes";
import { getLatestInstruments, getLatestDepositCurrencies } from "../../lib/funcs";
import { Dispatch } from "redux";

export const updateInstruments = (updates: IInstrument[]): InstrumentsActionTypes => ({
  type: UPDATE_INSTRUMENTS,
  payload: updates
});

export const loadInitialInstruments = async (dispatch: Dispatch<InstrumentsActionTypes>) => {
  try {
    let instruments: IInstrument[] = getLatestInstruments();
    dispatch(updateInstruments(instruments));
  } catch (error) {
    console.error('Error loading initial state from Chrome storage:', error);
  }
};

export const updateDepositCurrencies = (updates: IDepositCurrency[]): DepositCurrenciesActionTypes => ({
  type: UPDATE_DEPOSIT_CURRENCIES,
  payload: updates
});

export const loadInitialDepositCurrencies = async (dispatch: Dispatch<DepositCurrenciesActionTypes>) => {
  try {
    let depositCurrencies: IDepositCurrency[] = getLatestDepositCurrencies();
    dispatch(updateDepositCurrencies(depositCurrencies));
  } catch (error) {
    console.error('Error loading initial state from Chrome storage:', error);
  }
};