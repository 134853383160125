import { useSelector } from "react-redux";
import { useLanguage } from "../../../lib/LanguageContext";
import FieldSwitcher from "../FieldSwitcher";
import FormGroup from "../FormGroup";
import SelectGroup from "../SelectGroup";
import { useDispatch } from "react-redux";
import { RootState } from "../../../store/store";

interface IInstrumentInput {
  selectedMarkets: string[];
  selectedInstrument: string | undefined;
  className?: string;
  updater: (updates: any) => any;
}

function InstrumentInput({
  selectedMarkets,
  selectedInstrument,
  className,
  updater
}: IInstrumentInput) {
  const { languageData } = useLanguage();
  const dispatch = useDispatch();
  const instruments = useSelector((state: RootState) => state.instruments);

  const changeInstrument = (ins: string | number) => {
    const instrument = instruments.find((i) => i.SymbolName === ins);

    dispatch(updater({ selectedInstrument: instrument?.SymbolName }))
  };

  const switches = [
    {
      label: "Forex",
      checked: selectedMarkets.some(sm => sm === "Forex")
    },
    {
      label: "Crypto",
      checked: selectedMarkets.some(sm => sm === "Crypto")
    }
  ];

  const switchesUpdated = (switches: ISwitch[]) => {
    const selectedMarkets = switches.filter(sw => sw.checked).map(sw => sw.label);

    const visibleInstruments = instruments.filter(ins => selectedMarkets.some(sm => sm === ins.Market));
    const selectedInstrumentObject = visibleInstruments.find(ins => ins.SymbolName === selectedInstrument);

    const newSelectedInstrument = 
            selectedInstrumentObject?.SymbolName ||
            visibleInstruments.find(vins => vins.SymbolName === "EUR/USD" || vins.SymbolName === "BTC/USD")?.SymbolName ||
            "EUR/USD";

    dispatch(updater({ 
      selectedMarkets, 
      selectedInstrument: newSelectedInstrument
    }));
  }

  return (
    <div className={className || "col-12"}>
      <div className="row" style={{ alignItems: "baseline" }}>
        <div className="col-6">
          <SelectGroup
            label={languageData.instrument}
            options={instruments
              .filter((ins) => selectedMarkets.some((sw) => ins.Market === sw))
              .map((ins) => ({
                value: ins.SymbolName,
                name: ins.DisplaySymbolName,
                longnote: ins.FullName,
                shortnote: ins.SymbolName,
                selected: selectedInstrument === ins.SymbolName,
              }))}
            setValue={changeInstrument}
          />
        </div>
        <div className="col-6">
          <FormGroup label={" "}>
            <FieldSwitcher switches={switches} setSwitches={switchesUpdated} />
          </FormGroup>
        </div>
      </div>
    </div>
  );
}

export default InstrumentInput;
