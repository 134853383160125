import React, { InputHTMLAttributes } from 'react';
import FormGroup from './FormGroup';
import { stringToId } from '../../lib/funcs';

function InputGroup(props: InputHTMLAttributes<HTMLInputElement> & { label: string, hint?: string, additionallabel?: any }) {
  return (
    <FormGroup label={props.label} hint={props.hint} additionallabel={props.additionallabel}>
        <input id={stringToId(props.label)} {...props} />
    </FormGroup>
  );
}

export default InputGroup;