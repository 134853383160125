import React from 'react';
import FieldSwitcher from './FieldSwitcher';
import FormGroup from './FormGroup';

function OptionGroup({label, options, setValue}: { label: string, options: ISelectOptions[], setValue: (value:string|number) => void }) {

  const changeRadioSwitches = () =>  {
    setValue(options.filter(op => !op.selected)[0].value);
  };

  return (
    <FormGroup label={label} oneLine>
        <FieldSwitcher noLine switches={options.map(op => ({ label: op.name, checked: op.selected || false }))} setSwitches={changeRadioSwitches} type={'radio'} />
    </FormGroup>
  );
}

export default OptionGroup;