import { useLanguage } from "../../lib/LanguageContext";
import { useArrangeMode, useShowDesc } from "../../lib/custom-hooks";
import { onlyNum, splitAndRender } from "../../lib/funcs";

interface IRiskRewardCalc {
  riskReward: any;
  setRiskReward: React.Dispatch<React.SetStateAction<any>>;
  calculate: (ratio: string) => string | void;
  correct: (ratio: string) => string;
  className?: string;
}

function RiskRewardCalc({
  riskReward,
  setRiskReward,
  calculate,
  correct,
  className,
}: IRiskRewardCalc) {
  const { languageData } = useLanguage();
  const arrangeMode = useArrangeMode();
  const showDesc = useShowDesc();
  const { risk_reward, risk_desc } = languageData[`mode_${arrangeMode}`] || {};

  function handleCalculate() {
    const riskRewardInput = document.getElementById("riskReward") as HTMLInputElement;
    if (riskRewardInput) {
      calculate(riskRewardInput.value);
    }
  }

  function handleInput(e: any) {
    const inputValue = correct(onlyNum(e.target.value));
    if (inputValue !== riskReward.ratio) {
      setRiskReward({ ...riskReward, ratio: inputValue, result: "" });
    }
  }

  const ratioInput = (
    <input
      id="riskReward"
      type="text"
      size={5}
      value={riskReward.ratio}
      onChange={handleInput}
    />
  );

  const calculateButton = (
    <input
      type="submit"
      className="btn btn-primary"
      value={languageData.calculate || "Calculate"}
      onClick={handleCalculate}
    />
  );

  const riskRewardText = risk_reward
    ? splitAndRender(risk_reward, {
        "%CALCULATE_BUTTON%": calculateButton,
        "%RISK_REWARD_RATIO_INPUT%": ratioInput,
        "%WIN_RATE_PERCENTAGE_INPUT%": <span className="result">{riskReward.result || ""}</span>,
      })
    : '';
  
  const riskRewardDesc = risk_desc
    ? splitAndRender(risk_desc, {
        "%RISK_REWARD_RATIO%": <b>{(riskReward.result && riskReward.ratio) || "..."}</b>,
        "%WIN_RATE_PERCENTAGE%": <b>{riskReward.result || "..."}</b>,
      })
    : '';

  return (
    <div className={className || "col-12"}>
      <div className="form-group">
        {riskRewardText && <div className="inline-box">{riskRewardText}</div>}
        {showDesc && riskRewardDesc && <div className="desc">{riskRewardDesc}</div>}
      </div>
    </div>
  );
}

export default RiskRewardCalc;
