import { ChangeEventHandler } from "react";
import { onlyNum } from "../../../lib/funcs";
import InputGroup from "../InputGroup";
import { useLanguage } from "../../../lib/LanguageContext";
import { useDispatch } from "react-redux";
import { updateLotsizeCalculator } from "../../../store/lotsize/actions";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface IBalanceInput {
  className?: string;
}

function BalanceInput({ className }: IBalanceInput) {
  const { languageData } = useLanguage();
  const lotsize = useSelector((state: RootState) => state.lotsize);
  const dispatch = useDispatch();

  const balanceChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(updateLotsizeCalculator({ accountBalance: onlyNum(e.target.value) }))
  };
  return (
    <div className={className || "col-12"}>
      <InputGroup
        type="text"
        label={languageData.account_balance}
        value={lotsize.accountBalance}
        onChange={balanceChanged}
      />
    </div>
  );
}

export default BalanceInput;
