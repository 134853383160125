import React from "react";
import axios from "axios";
import { API_URL } from "../config";
import { errorMessage } from "./message";
import { DEFAULTS, DEFAULT_LOTSIZE_STATE, DEFAULT_MARGIN_STATE, DEFAULT_MOBILE_APP_SETTINGS, DEFAULT_PIPVALUE_STATE, DEFAULT_PROFIT_STATE, DEFAULT_STOP_LOSS } from "./constants";
import { Dispatch } from "redux";
import { setSavedFormInfo } from "../store/variables/varActions";
import { VariableActionTypes } from "../store/variables/varActionTypes";
import { updateDepositCurrencies, updateInstruments } from "../store/shared/actions";
import { updateLotsizeCalculator } from "../store/lotsize/actions";
import { updateMarginCalculator } from "../store/margin/actions";
import { updateProfitCalculator } from "../store/profit/actions";
import { updatePipvalueCalculator } from "../store/pipvalue/actions";

export const onlyNum = (str: string) => {
  const result = (str.match(/^([0-9\.]+)/) || [''])[0].split('.');
  return result.filter((s: string, i: number) => i < 2).join('.') + result.filter((s: string, i: number) => i >= 2).join('');
};

export const isDigits = (str: string) => {
  const val = str;
  if (!val.length || val.match(/^([0-9]+)$/)) return (val.match(/^([0-9]+)/) || [''])[0];
  return false;
}

export const utoa = (str: string) => {
  return globalThis.btoa(unescape(encodeURIComponent(str)));
}

export const atou = (str: string) => {
  return decodeURIComponent(escape(globalThis.atob(str)));
}

export const sleep = (t: number) => new Promise((res) => setTimeout(res, t));

export const getPrices = async (forceLocal = false, setShowForceUpdating = (f: boolean) => { }) => {
  let clb = (data: any) => { };
  let err = (err: any) => { };

  let prices: any;
  try {
    prices = JSON.parse(localStorage.getItem("prices") || '{}');
    if (!prices.dt) throw new Error("Looks like its the first time. This aint error!");
    if (forceLocal) {
      setTimeout(() => {
        clb(prices);
        setShowForceUpdating(false);
      }, 100);
    } else {
      const lastSaveDt = new Date(prices.dt);
      lastSaveDt.setSeconds(lastSaveDt.getSeconds() + 20)
      if (lastSaveDt.getTime() > new Date().getTime()) {
        setTimeout(() => clb(prices), 100);
        forceLocal = true;
        setShowForceUpdating(false);
      }
    }
  } catch (e) { setShowForceUpdating(false); console.log(e); err(e); }
  if (!forceLocal) {
    setShowForceUpdating(true);
    axios.get(API_URL + 'prices.json').then(res => {
      if (res.data && res.data.success) {
        try {
          (window as any).latestInstruments = res.data.currencyPairs?.map((cp: IInstrument) => ({
            ...cp,
            tradeSizeInfo: {
              value: 1,
              bases: { Lots: 1, Units: cp.LotSize },
              base: "Lots",
            },
          }));
          (window as any).latestDepositCurrencies = res.data.currencyList;
          localStorage.setItem("prices", JSON.stringify({ ...res.data, dt: new Date().toISOString() }));
        } catch (e) { }
        clb({ ...res.data, reloadedPrices: true });
      } else throw new Error(res.data || { message: "Error Loading Prices" });
    }).catch(async e => {
      let msg = e.message || 'Oops! There was an error loading latest currency list';
      if (msg.toLowerCase().indexOf("network error") > -1) {
        msg += '! Please check your internet connection';
      }
      errorMessage(msg);
      setShowForceUpdating(false);
      err(e);
    });
  }

  return {
    then: (c: (data: any) => void) => clb = c,
    catch: (c: (e: string) => void) => err = c
  }
}

export const formatResultNum = (num: number | string, fractionCount: number) => {
  const result = Number(num).toLocaleString('en', { minimumFractionDigits: fractionCount });
  const fractions = result.split('.')[1];
  if (!fractions) return result;
  if (Number(fractions) === 0) return result.split('.')[0];
  const r = [result.split('.')[0]];
  const fracs = fractions.substring(0, ((f) => { for (let j = f.length - 1; j >= 0; j--) if (f[j] !== '0') return j + 1; })(fractions));
  if (fracs) r.push(fracs);
  return r.join('.');
}

export const depositCurrencyMarket = (depositCurrency: string) => {
  const i = getLatestInstruments().find((ins: IInstrument) => {
    return ins.BaseCurrency === depositCurrency
  });
  return i?.Market || "Forex";
}

export const stringToId = (str: string): string => {
  return utoa(str).split("=").join("");
}

export const genericTranslate = (languageData: any, text: string | undefined) => {
  const prop = text?.toLowerCase().split(" ").join("_") || "";
  if (languageData.hasOwnProperty(prop)) return languageData[prop];
  return text;
}

export const getLanguageDirection: (languageData: any) => "ltr" | "rtl" = (languageData: any) => languageData?.__DIRECTION__ || "ltr";

export const splitAndRender = (text: string, replacements: any) => {
  const parts = text.split(/(\<br\>|%[A-Z_]+%)/);
  return parts.map((part, index) =>
    part === "<br>" ? (
      <br key={index} />
    ) : replacements[part] ? (
      <React.Fragment key={index}>{replacements[part]}</React.Fragment>
    ) : (
      <React.Fragment key={index}>{part}</React.Fragment>
    )
  );
};

export const getMobileAppSettings: () => ISettings = () => {
  try {
    const settings = JSON.parse(localStorage.getItem("MOBILE_APP_SETTINGS") || JSON.stringify(DEFAULT_MOBILE_APP_SETTINGS));
    return settings;
  } catch (e) {
    return DEFAULT_MOBILE_APP_SETTINGS;
  }
}

export const setMobileAppSettings: (settings: ISettings) => void = (settings) => {
  try {
    localStorage.setItem("MOBILE_APP_SETTINGS", JSON.stringify(settings));
  } catch (e) { }
}

export const genStopLoss = (price: number, pipSize: number, pips: number = DEFAULT_STOP_LOSS) => {
  let sl = price - pips * pipSize;
  // while(sl < 0) {
  //     pips = pips / 10;
  //     sl = price - pips * pipSize;
  // }
  return sl;
}

export const getSavedFormInfo: () => ISavedFormItem = () => {
  try {
    return JSON.parse(localStorage.getItem("SAVED_FORM_INFO") || "{}");
  } catch (e) {
    return {};
  }
}

export const saveSavedFormInfoToStorage = (savedFormInfo: ISavedFormItem) => {
  try {
    localStorage.setItem("SAVED_FORM_INFO", JSON.stringify(savedFormInfo));
  } catch (e) { }
}

export const saveOnePip = (instrument: string, onePip: string, dispatch: Dispatch<VariableActionTypes>) => {
  if (!onePip.length || !Number(onePip)) return;
  const savedFormInfo = getSavedFormInfo();
  savedFormInfo[instrument] = {
    ...(savedFormInfo[instrument] || {}),
    onePip
  };
  saveSavedFormInfoToStorage(savedFormInfo);
  dispatch(setSavedFormInfo(savedFormInfo));
}


export const saveLotUnits = (instrument: string, unitsOf1Lot: string, dispatch: Dispatch<VariableActionTypes>) => {
  if (!unitsOf1Lot.length || !Number(unitsOf1Lot)) return;
  const savedFormInfo = getSavedFormInfo();
  savedFormInfo[instrument] = {
    ...(savedFormInfo[instrument] || {}),
    unitsOf1Lot
  };
  saveSavedFormInfoToStorage(savedFormInfo);
  dispatch(setSavedFormInfo(savedFormInfo));
}

export const getIsMobile = (location: any): boolean => {
  const queryParams = new URLSearchParams(location.search);
  const mobileParam = queryParams.get('mobile');
  return mobileParam ? !!Number(mobileParam) : false;
}

const DEFAULT_CALC_CACHE = {
  margin: {},
  profit: {},
  lotsize: {},
  pipvalue: {},
  rrwinrate: {}
};

export const getLatestCalculatorState = () => {
  try {
    return JSON.parse(localStorage.getItem("MOBILE_APP_FXCALC_CACHES") || JSON.stringify(DEFAULT_CALC_CACHE));
  } catch (e) {
    return DEFAULT_CALC_CACHE;
  }
}

const generateCalculatorCaches = () => {
  const settings = getMobileAppSettings();
  if (settings.rememberChanges) {
    return getLatestCalculatorState();
  }
  return {
    margin: {},
    profit: {},
    lotsize: {},
    pipvalue: {},
    rrwinrate: {}
  }
}

const cacheChanged = () => {
  try {
    localStorage.setItem("MOBILE_APP_FXCALC_CACHES", JSON.stringify(window.fxcalc_caches));
  } catch (e) { }
}

window.fxcalc_caches = generateCalculatorCaches();

export const getCaches = (calculator: ICalculators) => {
  return window.fxcalc_caches[calculator];
}

export const setCaches = (calculator: ICalculators, values: object) => {
  window.fxcalc_caches[calculator] = { ...values };
  cacheChanged();
}

export const clearAllCaches = () => {
  (["pipvalue", "lotsize", "margin", "profit", "rrwinrate"] as ICalculators[]).forEach((calculator) => {
    window.fxcalc_caches[calculator] = {};
  });
  cacheChanged();
}

export const getCache = (calculator: ICalculators, prop: string) => {
  return window.fxcalc_caches[calculator][prop] || DEFAULTS[calculator][prop];
}

export const setCache = (calculator: ICalculators, prop: string, value: any) => {
  window.fxcalc_caches[calculator][prop] = value;
  cacheChanged();
}

const getState: <T>(stateName: string, DEFAULT_STATE: T) => T = (stateName, DEFAULT_STATE) => {
  try {
    const settings = getMobileAppSettings();
    if(settings.rememberChanges) {
      return JSON.parse(localStorage.getItem(stateName) || JSON.stringify(DEFAULT_STATE));
    }
  } catch (e) {
    return DEFAULT_STATE;
  }
}

const setState: <T>(stateName: string, NEW_STATE: T, DEFAULT_STATE: T) => void = (stateName, NEW_STATE, DEFAULT_STATE) => {
  try {
    localStorage.setItem(stateName, JSON.stringify(NEW_STATE || DEFAULT_STATE));
  } catch (e) {
    console.log("Error saving state to the local storage...", e);
  }
}

export const getPipvalueState: () => IPipvalueFields = () => {
  return getState("MOBILE_APP_PIPVALUE_STATE", DEFAULT_PIPVALUE_STATE);
}

export const setPipvalueState: (NEW_PIPVALUE_STATE?: IPipvalueFields) => void = (NEW_PIPVALUE_STATE) => {
  return setState("MOBILE_APP_PIPVALUE_STATE", NEW_PIPVALUE_STATE, DEFAULT_PIPVALUE_STATE);
}

export const getMarginState: () => IMarginFields = () => {
  return getState("MOBILE_APP_MARGIN_STATE", DEFAULT_MARGIN_STATE);
}

export const setMarginState: (NEW_MARGIN_STATE?: IMarginFields) => void = (NEW_MARGIN_STATE) => {
  return setState("MOBILE_APP_MARGIN_STATE", NEW_MARGIN_STATE, DEFAULT_MARGIN_STATE);
}

export const getLotsizeState: () => ILotsizeFields = () => {
  return getState("MOBILE_APP_LOTSIZE_STATE", DEFAULT_LOTSIZE_STATE);
}

export const setLotsizeState: (NEW_LOTSIZE_STATE?: ILotsizeFields) => void = (NEW_LOTSIZE_STATE) => {
  return setState("MOBILE_APP_LOTSIZE_STATE", NEW_LOTSIZE_STATE, DEFAULT_LOTSIZE_STATE);
}

export const getProfitState: () => IProfitFields = () => {
  return getState("MOBILE_APP_PROFIT_STATE", DEFAULT_PROFIT_STATE);
}

export const setProfitState: (NEW_PROFIT_STATE?: IProfitFields) => void = (NEW_PROFIT_STATE) => {
  return setState("MOBILE_APP_PROFIT_STATE", NEW_PROFIT_STATE, DEFAULT_PROFIT_STATE);
}

export const clearAllStoredInfo = (dispatch: Dispatch<any>) => {
  setLotsizeState(DEFAULT_LOTSIZE_STATE);
  setPipvalueState(DEFAULT_PIPVALUE_STATE);
  setProfitState(DEFAULT_PROFIT_STATE);
  setMarginState(DEFAULT_MARGIN_STATE);
  dispatch(updateLotsizeCalculator(DEFAULT_LOTSIZE_STATE));
  dispatch(updateMarginCalculator(DEFAULT_MARGIN_STATE));
  dispatch(updateProfitCalculator(DEFAULT_PROFIT_STATE));
  dispatch(updatePipvalueCalculator(DEFAULT_PIPVALUE_STATE));
  dispatch(setSavedFormInfo({}));
}


export const getLatestInstruments: () => IInstrument[] = () => {
  try {
    return (window as any).latestInstruments || (JSON.parse(localStorage.getItem("prices") || "{}").currencyPairs?.map((cp: IInstrument) => ({
      ...cp,
      tradeSizeInfo: {
        value: 1,
        bases: { Lots: 1, Units: cp.LotSize },
        base: "Lots",
      },
    }))) || require("./default-instruments.json");
  } catch (e) {}
  return require("./default-instruments.json");
}

export const getLatestDepositCurrencies: () => IDepositCurrency[] = () => {
  let depositCurrencies: IDepositCurrency[] = [];
  try {
    depositCurrencies = (window as any).latestDepositCurrencies || JSON.parse(localStorage.getItem("prices") || '{}').currencyList || require("./default-currencies.json");
    return depositCurrencies;
  } catch (e) { }
  return require("./default-currencies.json");
}

export const updatePricesGlobally = async (dispatch: Dispatch<any>, forceLocal = false) => {
  return getPrices(forceLocal).then((data) => {
    if (data && data.success) {
      const currencyPairs: IInstrument[] = data.currencyPairs.map((cp: IInstrument) => ({
        ...cp,
        tradeSizeInfo: {
          value: 1,
          bases: { Lots: 1, Units: cp.LotSize },
          base: "Lots",
        },
      }));
      dispatch(updateInstruments(currencyPairs));
      dispatch(updateDepositCurrencies(data.currencyList));
    }
    return { shouldReloadPrice: !!data.reloadedPrices };
  });
}

export const getInstrumentBySymbolName = (symbolName: string) => {
  const instruments = getLatestInstruments() as IInstrument[];
  return instruments.find(ins => ins.SymbolName === symbolName) as IInstrument;
}

export const getPipsByStopLoss = (openPrice: number | string, onePip: number | string, stopLoss: number | string) => {
  return Number((Math.abs(Number(openPrice) - Number(stopLoss)) / Number(onePip)).toFixed(1));
}
