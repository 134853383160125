import React, { ReactNode, useState, useRef, useEffect } from "react";
import styles from "./FormGroup.module.scss";
import { genericTranslate, stringToId } from "../../lib/funcs";
import { useLanguage } from "../../lib/LanguageContext";

function FormGroup({
  label,
  children,
  oneLine,
  hint,
  additionallabel,
}: {
  label: string;
  children: ReactNode;
  oneLine?: boolean;
  hint?: string;
  additionallabel?: any;
}) {
  const [showHint, setShowHint] = useState(false);
  const { languageData } = useLanguage();
  const hintRef = useRef<HTMLDivElement>(null);
  const [hintContentStyle, setHintContentStyle] = useState<React.CSSProperties>({});

  const displayHint = () => {
    setShowHint(true);
  };

  const hideHint = () => {
    setShowHint(false);
  };
  
  const cuurentLanguage = new URLSearchParams(window.location.search).get(
    "lang"
  );
  const isRtl = cuurentLanguage === "ar" || cuurentLanguage === "fa";

  let hintInfoStyle: React.CSSProperties = isRtl
    ? { marginRight: "0.5em" }
    : { marginLeft: "0.5em" };

  useEffect(() => {
    if (hintRef.current) {
      const hintIconRect = hintRef.current.getBoundingClientRect();
      const isIconOnRightSide = hintIconRect.x > window.innerWidth / 2;
      
      const newHintContentStyle = {
        left: isIconOnRightSide ? "auto" : "-10px",
        right: isIconOnRightSide ? "-10px" : "auto"
      };
  
      setHintContentStyle(newHintContentStyle);
    }
  }, [showHint]);    

  return (
    <div className={[styles.inputGroup, oneLine ? styles.oneLine : ""].join(" ")}>
      <div className={[styles.labelContainer, additionallabel ? styles.hasAdditionalLabel : ""].join(" ")}>
        <label htmlFor={stringToId(label)}>
          {label}
        </label>
        {additionallabel}
        {hint ? (
          <div
            ref={hintRef}
            className={styles.hintInfo}
            style={hintInfoStyle}
            onMouseEnter={displayHint}
            onMouseLeave={hideHint}
          >
            <span className={styles.infoIcon} />
            {showHint ? (
              <div className={styles.hintBubble}>
                <span className={styles.hintArrow} />
                <span className={styles.hintContent} style={hintContentStyle}>
                  {genericTranslate(languageData, hint)}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={styles.inputContainer}>{children}</div>
    </div>
  );
}

export default FormGroup;
