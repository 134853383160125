import { ChangeEventHandler, Dispatch, SetStateAction } from "react";
import { genericTranslate, onlyNum } from "../../../lib/funcs";
import FieldSwitcher from "../FieldSwitcher";
import FormGroup from "../FormGroup";
import InputGroup from "../InputGroup";
import { useLanguage } from "../../../lib/LanguageContext";
import styles from "./TradeSizeInput.module.scss";
import { useDispatch } from "react-redux";

interface ITradeSizeInput {
  updater: (updates: any) => any;
  tradeSizeInfo: ITradeSizeInfo;
  className?: string;
  unitsOfOneLot: string | undefined;
}

function TradeSizeInput({
  className,
  tradeSizeInfo,
  updater,
  unitsOfOneLot
}: ITradeSizeInput) {

  const dispatch = useDispatch();

  const tradeSizeChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(updater({ 
      tradeSizeInfo: { ...tradeSizeInfo, value: onlyNum(e.target.value) }
    }));
  };
  const { languageData } = useLanguage();
  const changeRadioSwitches = () => {
    let tsbKey: "Units" | "Lots" =
      tradeSizeInfo.base === "Lots" ? "Units" : "Lots";
    dispatch(
      updater({ 
        tradeSizeInfo: 
          (tradeSizeInfo ? { 
            ...tradeSizeInfo, 
            base: tsbKey,
            value: tsbKey === "Lots" ? Number(tradeSizeInfo.value) / Number(unitsOfOneLot) : Number(tradeSizeInfo.value) * Number(unitsOfOneLot),
          } : 
          { base: "Lots", value: 1, unitsOf1Lot: Number(unitsOfOneLot) })
      })
    );
  };

  return (
    <div className={[styles.tradeSizeInfo, className || "col-12"].join(" ")}>
      <div className="row" style={{ alignItems: "baseline" }}>
        <div className={[styles.inputGroup, "col-6"].join(" ")}>
          <InputGroup
            type="text"
            label={`${languageData.trade_size} (${genericTranslate(
              languageData,
              tradeSizeInfo.base
            )})`}
            value={tradeSizeInfo.value}
            onChange={tradeSizeChanged}
          />
        </div>
        <div className="col-6">
          <FormGroup label={" "}>
            <FieldSwitcher
              switches={[
                {
                  label: "Lots",
                  checked: "Lots" === tradeSizeInfo.base,
                },
                {
                  label: "Units",
                  checked: "Units" === tradeSizeInfo.base,
                },
              ]}
              setSwitches={changeRadioSwitches}
              type={"radio"}
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
}

export default TradeSizeInput;
