import { useEffect, useState } from "react";
import styles from "./ToggleInput.module.scss";

interface IToggleOption {
  text: string;
  value?: boolean;
}

interface IToggleInput {
  options: IToggleOption[];
  defaultValue: boolean;
  onToggle: (newValue: boolean) => any;
}

function ToggleInput({ options, defaultValue, onToggle }: IToggleInput) {
  const [value, setValue] = useState(defaultValue);
  const doubleOption = options.length === 2;

  const changeValue = (newVal: boolean) => {
    setValue(newVal);
    onToggle(newVal);
  }

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className={[styles.toggle, !doubleOption ? styles.singleOption : ""].join(" ")}>
      {doubleOption ? (
        <label onClick={() => changeValue(false)}>{options[1].text}</label>
      ) : null}
      <input type="checkbox" checked={value === options[0].value} onChange={(e) => setValue(value)} />
      <div className={styles.toggleHolder} onClick={() => changeValue(!value)}>
        <span className={styles.bar} />
        <span className={styles.pos} />
      </div>
      <label onClick={() => changeValue(doubleOption ? true : !value)}>{options[0].text}</label>
    </div>
  );
}

export default ToggleInput;
