export const OPERATIONS = {
  MUL: "mul",
  DIV: "div",
};

export const STOPLOSS_TYPES = {
  PIPS: 1,
  CURRENCY: 2,
};

export const DEFAULT_MOBILE_APP_SETTINGS: ISettings = {
  ESMALeverage: false,
  rememberChanges: false,
  keepScreenAwake: false
}

export const DEFAULT_STOP_LOSS = 80;

export const DEFAULT_ONE_PIP = "0.0001";

const GENERAL_DEFAULTS = {
  depositableCurrencies: [],
  depositCurrencies: [],
  instruments: [],
  switches: [
    { label: "Forex", checked: true },
    { label: "Crypto", checked: true },
  ],
  price: 1.0,
  lotUnits: 100000,
  tradeSizeInfo: {
    base: "Lots",
    value: 1,
    unitsOf1Lot: 100000,
  }
}

export const DEFAULTS: { [key in ICalculators]: any } = {
  lotsize: {
    ...GENERAL_DEFAULTS,
    openPrice: 1.0,
    stopLoss: DEFAULT_STOP_LOSS,
    balance: "1000",
    risk: "2",
    riskType: "PERCENT",
    onePip: DEFAULT_ONE_PIP,
    stopLossType: STOPLOSS_TYPES.PIPS
  },
  margin: {
    ...GENERAL_DEFAULTS,
    leverage: "100"
  },
  profit: {
    ...GENERAL_DEFAULTS,
    openPrice: 1.0,
    closePrice: 1.01,
    selectedPositionDirection: {
      value: 1,
      name: "Buy",
      desc: "Long",
    }
  },
  pipvalue: {
    ...GENERAL_DEFAULTS,
    pips: 1,
    onePip: DEFAULT_ONE_PIP
  },
  rrwinrate: {
    riskReward: {},
    winRate: {}
  }
}

export const DEFAULT_LOTSIZE_STATE: ILotsizeFields = {
  selectedInstrument: "EUR/USD",
  selectedMarkets: ["Crypto", "Forex"],
  selectedDepositCurrency: "AUD",
  depositableCurrencies: [],
  risk: "2",
  riskType: "PERCENT",
  accountBalance: "1000",
  openPrice: "",
  stopLoss: DEFAULT_STOP_LOSS.toString(),
  stopLossType: STOPLOSS_TYPES.PIPS,
  unitsOfOneLot: "100000",
  price: "",
  priceUpdatedAt: 0,
  onePip: DEFAULT_ONE_PIP
}


export const DEFAULT_MARGIN_STATE: IMarginFields = {
  selectedInstrument: "EUR/USD",
  selectedMarkets: ["Crypto", "Forex"],
  selectedDepositCurrency: "AUD",
  depositableCurrencies: [],
  leverage: "100",
  unitsOfOneLot: "100000",
  price: "",
  priceUpdatedAt: 0,
  tradeSizeInfo: {
    base: "Lots",
    value: 1
  }
}


export const DEFAULT_PIPVALUE_STATE: IPipvalueFields = {
  selectedInstrument: "EUR/USD",
  selectedMarkets: ["Crypto", "Forex"],
  selectedDepositCurrency: "AUD",
  depositableCurrencies: [],
  pips: "1",
  onePip: DEFAULT_ONE_PIP,
  unitsOfOneLot: "100000",
  price: "",
  priceUpdatedAt: 0,
  tradeSizeInfo: {
    base: "Lots",
    value: 1
  }
}


export const DEFAULT_PROFIT_STATE: IProfitFields = {
  selectedInstrument: "EUR/USD",
  selectedMarkets: ["Crypto", "Forex"],
  selectedDepositCurrency: "AUD",
  depositableCurrencies: [],
  pips: "1",
  onePip: DEFAULT_ONE_PIP,
  unitsOfOneLot: "100000",
  price: "",
  priceUpdatedAt: 0,
  selectedPositionDirection: {
    value: "1",
    name: "Buy",
    desc: "Long",
  },
  tradeSizeInfo: {
    base: "Lots",
    value: 1
  }
}


export const defaultInstruments = () => require("./default-instruments.json");

export const defaultDepositCurrencies = () => require("./default-currencies.json");
