import React, { useEffect, useMemo, useRef, useState } from "react";
import Calculator from "./global/Calculator";
import DepositCurrencyInput from "./global/inputs/DepositCurrencyInput";
import InstrumentInput from "./global/inputs/InstrumentInput";
import OnePip from "./global/inputs/OnePip";
import PipsInput from "./global/inputs/PipsInput";
import PriceInput from "./global/inputs/PriceInput";
import UnitsOf1Lot from "./global/inputs/UnitsOf1Lot";
import {
  useArrangeMode,
  useElementSize,
} from "../lib/custom-hooks";
import { depositCurrencyMarket, formatResultNum, getInstrumentBySymbolName } from "../lib/funcs";
import TradeSizeInput from "./global/inputs/TradeSizeInput";
import { useLanguage } from "../lib/LanguageContext";
import { DEFAULT_ONE_PIP, OPERATIONS } from "../lib/constants";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { loadInitialPipvalueFields, updatePipvalueCalculator } from "../store/pipvalue/actions";

function PipValueCalculator(props: { noLine?: boolean }) {
  const { languageData } = useLanguage();
  const defaultResult = useMemo(() => ({ name: languageData.result, result: "----" }), [languageData.result]);
  const elementSize = useElementSize();
  const arrangeMode = useArrangeMode();

  const pipvalue = useSelector((state: RootState) => state.pipvalue);

  const [result, setResult] = useState<IResult>([defaultResult]);

  const calculate: React.MouseEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    let result: string = "";
    if (!pipvalue.selectedInstrument)
      return setResult([{ name: "Error", result: "No Instrument Selected" }]);
    if (!pipvalue.selectedDepositCurrency)
      return setResult([
        { name: "Error", result: "No Deposit Currency Selected" },
      ]);
    if (!pipvalue.pips)
      return setResult([{ name: "Error", result: "Please Enter Pips" }]);
    if (!pipvalue.tradeSizeInfo?.value || !Number(pipvalue.tradeSizeInfo?.value))
      return setResult([
        {
          name: "Error",
          result: "Trade Size can't be " + (pipvalue.tradeSizeInfo?.value || "empty"),
        },
      ]);
    if (!pipvalue.unitsOfOneLot || !Number(pipvalue.unitsOfOneLot))
      return setResult([
        {
          name: "Error",
          result: "Units of 1 lot can't be " + (pipvalue.unitsOfOneLot || "empty"),
        },
      ]);
    const fractionCount =
      depositCurrencyMarket(pipvalue.selectedDepositCurrency) === "Forex"
        ? 2
        : (pipvalue.price + "").split(".")[0].length + 2;
    let tSize =
      pipvalue.tradeSizeInfo.base === "Units"
        ? pipvalue.tradeSizeInfo.value
        : Number(pipvalue.tradeSizeInfo.value) * Number(pipvalue.unitsOfOneLot);
    const r1 = Number(tSize) * Number(typeof pipvalue.onePip === "undefined" ? DEFAULT_ONE_PIP : pipvalue.onePip) * Number(pipvalue.pips);
    if (pipvalue.selectedInstrument.startsWith(`${pipvalue.selectedDepositCurrency}/`)) {
      result =
        formatResultNum(r1 / Number(pipvalue.price), fractionCount) +
        " " +
        pipvalue.selectedDepositCurrency;
    } else if (pipvalue.selectedInstrument.endsWith(`/${pipvalue.selectedDepositCurrency}`)) {
      result =
        formatResultNum(r1, fractionCount) +
        " " +
        pipvalue.selectedDepositCurrency;
    } else {
      if (pipvalue.priceInstrument?.operation === OPERATIONS.MUL) {
        result =
          formatResultNum(r1 * Number(pipvalue.price), fractionCount) +
          " " +
          pipvalue.selectedDepositCurrency;
      } else {
        result =
          formatResultNum(r1 / Number(pipvalue.price), fractionCount) +
          " " +
          pipvalue.selectedDepositCurrency;
      }
    }
    setResult([{ name: languageData.result, result }]);
  };

  useEffect(() => {
    const updatedDefaultResult = [
      { name: languageData.result, result: defaultResult.result },
    ];
    setResult(updatedDefaultResult);
  }, [defaultResult.result, languageData]);

  useEffect(() => {
    setResult([defaultResult]);
  }, [pipvalue]);

  return (
    <Calculator
      noLine={props.noLine}
      result={result}
      calculate={calculate}
      updater={updatePipvalueCalculator}
      // initializer={loadInitialPipvalueFields}
    >
      {arrangeMode !== 2 && arrangeMode !== 3 ? (
        <PipsInput className={"col-12"} />
      ) : null}

      <InstrumentInput
        className={`col-${Math.min(12, elementSize * 2)}`}
        selectedMarkets={pipvalue.selectedMarkets}
        selectedInstrument={pipvalue.selectedInstrument}
        updater={updatePipvalueCalculator}
      />

      <DepositCurrencyInput
        className={`col-${Math.max(6, Math.min(12, elementSize))}`}
        updater={updatePipvalueCalculator}
        depositableCurrencies={pipvalue.depositableCurrencies}
        selectedDepositCurrency={pipvalue.selectedDepositCurrency}
        selectedInstrument={pipvalue.selectedInstrument}
      />

      {arrangeMode !== 2 && arrangeMode !== 3 ? null : (
        <PipsInput
          className={`col-${Math.max(6, Math.min(12, elementSize))}`}
        />
      )}

      {pipvalue.tradeSizeInfo ? (
        <TradeSizeInput
          className={`col-${Math.min(12, elementSize * 2)}`}
          updater={updatePipvalueCalculator}
          unitsOfOneLot={pipvalue.unitsOfOneLot || (getInstrumentBySymbolName(pipvalue.selectedInstrument).LotSize.toString())}
          tradeSizeInfo={pipvalue.tradeSizeInfo}
        />
      ) : null}

      <PriceInput
        updater={updatePipvalueCalculator}
        className={`col-${Math.min(12, elementSize * 2)}`}
        priceInstrument={pipvalue.priceInstrument}
        priceUpdatedAt={pipvalue.priceUpdatedAt}
        price={pipvalue.price}
      />

      <UnitsOf1Lot
        className={
          arrangeMode === 2 ? "col-6" : arrangeMode === 3 ? "col-3" : "col-12"
        }
        updater={updatePipvalueCalculator}
        selectedInstrument={pipvalue.selectedInstrument}
        unitsOfOneLot={pipvalue.unitsOfOneLot}
        tradeSizeInfo={pipvalue.tradeSizeInfo}
        calc="pipvalue"
      />

      <OnePip
        className={
          arrangeMode === 2 ? "col-6" : arrangeMode === 3 ? "col-3" : "col-12"
        }
        selectedInstrument={pipvalue.selectedInstrument}
        updater={updatePipvalueCalculator}
        onePip={typeof pipvalue.onePip === "undefined" ? DEFAULT_ONE_PIP : pipvalue.onePip}
      />
    </Calculator>
  );
}

export default PipValueCalculator;
