import { useEffect, useMemo, useState } from "react";
import RateCalculator from "./global/RateCalculator";
import RiskRewardCalc from "./global/RiskRewardCalc";
import WinRateCalc from './global/WinRateCalc';
import RiskRewardWinRateCalc from './global/RiskRewardWinRateCalc';
import ResultOutput from "./global/ResultOutput";
import {
  useShowAds,
  useElementSize,
  useArrangeMode,
} from "../lib/custom-hooks";
import { setCaches, getCache, getCaches } from "../lib/funcs";

function RRWinRateCalculator(props: { noLine?: boolean }) {
  const arrangeMode = useArrangeMode();
  const elementSize = useElementSize();
  const showAds = useShowAds(['forex', 'crypto']);
  const defaultError = useMemo(() => ([ { name: '', result: '' } ]), []);
  const [error, setError] = useState<IResult>(defaultError);

  const getInitialCache = (key: string) => ({
    ...getCache("rrwinrate", key),
    ratio: '',
    result: '',
  });

  const [riskReward, setRiskReward] = useState<any>(getInitialCache("riskReward"));
  const [winRate, setWinRate] = useState<any>(getInitialCache("winRate"));

  const handleDecimalPoint = (ratio: string) => {
    if (!ratio) return ratio;

    const hasDecimalPoint = ratio.includes('.');
    if (hasDecimalPoint && ratio.split('.')[1]?.length > 2) {
        const [integerPart, decimalPart] = ratio.split('.');
        const truncatedDecimal = decimalPart?.slice(0, 2);

        const value = Number(ratio) < 0.01
            ? 0.01
            : parseFloat(`${integerPart}.${truncatedDecimal || ''}`);

        return value.toFixed(2);
    }

    return ratio;
  };

  const correctRisk = (ratio: string) => {
    if (!ratio) return "";
    if (Number(ratio) > 100) return "100";

    return handleDecimalPoint(ratio);
  };

  const correctWin = (ratio: string) => {
    if (!ratio) return "";
    if (Number(ratio) > 99) return "99";
    if (Number(ratio) < 1) return "1";

    return handleDecimalPoint(ratio);
  };

  const calculateRisk = (ratio: string) => {
    if (!ratio) return setError([{ name: "Error", result: "No ratio input" }]);
    const parsedRatio = parseFloat(ratio);
    if (isNaN(parsedRatio)) {
      return setError([{ name: "Error", result: "Invalid ratio input" }]);
    }
    if (parsedRatio > 100) {
      return setError([{ name: "Error", result: "Ratio cannot exceed 100" }]);
    }
    if (parsedRatio < 0.01) {
      return setError([{ name: "Error", result: "Ratio cannot be less than 0.01" }]);
    }
    const roundedRatio = parsedRatio.toFixed(2);
    const formula_calc: number = (1 / (1 + parseFloat(roundedRatio))) * 100;
    setRiskReward({
      ...riskReward,
      ratio,
      result: parseFloat(formula_calc.toFixed(2)),
    });
  };

  const calculateWin = (ratio: string) => {
    if (!ratio) return setError([{ name: "Error", result: "No win rate input" }]);
    const parsedRatio = parseFloat(ratio);
    if (isNaN(parsedRatio)) {
      return setError([{ name: "Error", result: "Invalid win rate input" }]);
    }
    if (parsedRatio > 99) {
      return setError([{ name: "Error", result: "Win rate cannot exceed 99" }]);
    }
    if (parsedRatio < 1) {
      return setError([{ name: "Error", result: "Win rate cannot be less than 1" }]);
    }
    const roundedRatio = parsedRatio.toFixed(2);
    const formula_calc: number = (1 / (parseFloat(roundedRatio) / 100)) - 1;
    setWinRate({
      ...winRate,
      ratio,
      result: parseFloat(formula_calc.toFixed(2))
    });
  };

  useEffect(() => {
    setError(defaultError);
    setCaches("rrwinrate", {
      ...getCaches("rrwinrate"),
      riskReward,
      winRate,
    });
  }, [defaultError, riskReward, winRate]);

  return (
    <RateCalculator
      noLine={props.noLine}
    >
      {(() => {
        if (arrangeMode === 3) {
          return (
            <RiskRewardWinRateCalc
              className="col-12 text-align-center"
              riskReward={riskReward}
              winRate={winRate}
              setRiskReward={setRiskReward}
              setWinRate={setWinRate}
              calculateRisk={calculateRisk}
              calculateWin={calculateWin}
              correctRisk={correctRisk}
              correctWin={correctWin}
              />
          );
        } else {
          return (
            <>
              <RiskRewardCalc
                className="col-12"
                riskReward={riskReward}
                setRiskReward={setRiskReward}
                calculate={calculateRisk}
                correct={correctRisk}
                />
              <hr />
              <WinRateCalc
                className="col-12"
                winRate={winRate}
                setWinRate={setWinRate}
                calculate={calculateWin}
                correct={correctWin}
                />
            </>
          );
        }
      })()}
      {error && error[0].name && (
        <ResultOutput
          className="col-12"
          vertical={showAds && elementSize === 6}
          flexStart={showAds}
          result={error}
          />
      )}
    </RateCalculator>
  );
}

export default RRWinRateCalculator;
