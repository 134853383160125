import { defaultDepositCurrencies, defaultInstruments } from "../../lib/constants";
import { InstrumentsActionTypes, DepositCurrenciesActionTypes, UPDATE_INSTRUMENTS, UPDATE_DEPOSIT_CURRENCIES } from "./actionTypes";


export const instrumentsReducer = (state = defaultInstruments(), action: InstrumentsActionTypes): IInstrument[] => {
  switch (action.type) {
    case UPDATE_INSTRUMENTS: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const depositCurrenciesReducer = (state = defaultDepositCurrencies(), action: DepositCurrenciesActionTypes): IDepositCurrency[] => {
  switch (action.type) {
    case UPDATE_DEPOSIT_CURRENCIES: {
      return action.payload;
    }
    default:
      return state;
  }
};
