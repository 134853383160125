import React, { useEffect, useMemo, useState } from "react";
import Calculator from "./global/Calculator";
import DepositCurrencyInput from "./global/inputs/DepositCurrencyInput";
import InstrumentInput from "./global/inputs/InstrumentInput";
import LeverageInput from "./global/inputs/LeverageInput";
import PriceInput from "./global/inputs/PriceInput";
import UnitsOf1Lot from "./global/inputs/UnitsOf1Lot";
import { useLanguage } from "../lib/LanguageContext";

import {
  useElementSize,
} from "../lib/custom-hooks";
import { depositCurrencyMarket, formatResultNum, getInstrumentBySymbolName } from "../lib/funcs";
import TradeSizeInput from "./global/inputs/TradeSizeInput";
import { OPERATIONS } from "../lib/constants";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { loadInitialMarginFields, updateMarginCalculator } from "../store/margin/actions";

function MarginCalculator(props: { noLine?: boolean }) {
  const { languageData } = useLanguage();
  const defaultResult = useMemo(() => ({ name: languageData.required_margin, result: "----" }), [languageData.required_margin]);
  const elementSize = useElementSize();

  const margin = useSelector((state: RootState) => state.margin);

  const [result, setResult] = useState<IResult>([defaultResult]);

  useEffect(() => {
    setResult([defaultResult]);
  }, [margin]);


  const calculate: React.MouseEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    let result: string = "";
    if (!margin.selectedInstrument)
      return setResult([{ name: "Error", result: "No Instrument Selected" }]);
    if (!margin.selectedDepositCurrency)
      return setResult([
        { name: "Error", result: "No Deposit Currency Selected" },
      ]);
    if (!margin.leverage)
      return setResult([{ name: "Error", result: "Please Enter Leverage" }]);
    if (!margin.tradeSizeInfo || !margin.tradeSizeInfo.value || !Number(margin.tradeSizeInfo.value))
      return setResult([
        {
          name: "Error",
          result: "Trade Size can't be " + (margin.tradeSizeInfo?.value || "empty"),
        },
      ]);
    if (!margin.unitsOfOneLot || !Number(margin.unitsOfOneLot))
      return setResult([
        {
          name: "Error",
          result: "Units of 1 lot can't be " + (margin.unitsOfOneLot || "empty"),
        },
      ]);
    let tSize =
      margin.tradeSizeInfo.base === "Units"
        ? margin.tradeSizeInfo.value
        : Number(margin.tradeSizeInfo.value) * Number(margin.unitsOfOneLot);
    const fractionCount =
      depositCurrencyMarket(margin.selectedDepositCurrency) === "Forex"
        ? 2
        : (margin.price + "").split(".")[0].length + 2;
    if (margin.selectedInstrument.startsWith(`${margin.selectedDepositCurrency}/`)) {
      result =
        formatResultNum(
          (Number(tSize) / Number(margin.leverage)).toFixed(fractionCount),
          fractionCount
        ) +
        " " +
        margin.selectedDepositCurrency;
    } else if (margin.selectedInstrument.endsWith(`/${margin.selectedDepositCurrency}`)) {
      result = (Number(tSize) / Number(margin.leverage)).toString();
      result =
        formatResultNum(
          (parseFloat(result) * Number(margin.price)).toFixed(fractionCount),
          fractionCount
        ) +
        " " +
        margin.selectedDepositCurrency;
    } else {
      let div = Number(tSize) / Number(margin.leverage);
      if (margin.priceInstrument?.operation === OPERATIONS.MUL) {
        result =
          formatResultNum(
            (div * Number(margin.price)).toFixed(fractionCount),
            fractionCount
          ) +
          " " +
          margin.selectedDepositCurrency;
      } else {
        result =
          formatResultNum(
            (div / Number(margin.price)).toFixed(fractionCount),
            fractionCount
          ) +
          " " +
          margin.selectedDepositCurrency;
      }
    }
    setResult([{ name: languageData.required_margin, result }]);
  };

  return (
    <Calculator
      noLine={props.noLine}
      result={result}
      calculate={calculate}
      updater={updateMarginCalculator}
      // initializer={loadInitialMarginFields}
    >
      <InstrumentInput
        className={`col-${Math.min(12, elementSize * 2)}`}
        updater={updateMarginCalculator}
        selectedInstrument={margin.selectedInstrument}
        selectedMarkets={margin.selectedMarkets}
      />

      <DepositCurrencyInput
        className={`col-${elementSize}`}
        updater={updateMarginCalculator}
        depositableCurrencies={margin.depositableCurrencies}
        selectedDepositCurrency={margin.selectedDepositCurrency}
        selectedInstrument={margin.selectedInstrument}
      />

      <LeverageInput
        className={`col-${elementSize}`}
      />

      {margin.tradeSizeInfo ? (
        <TradeSizeInput
          className={`col-${Math.min(12, elementSize * 2)}`}
          updater={updateMarginCalculator}
          unitsOfOneLot={margin.unitsOfOneLot || (getInstrumentBySymbolName(margin.selectedInstrument).LotSize.toString())}
          tradeSizeInfo={margin.tradeSizeInfo}
        />
      ) : null}

      <PriceInput
        updater={updateMarginCalculator}
        className={`col-${elementSize}`}
        priceInstrument={margin.priceInstrument}
        priceUpdatedAt={margin.priceUpdatedAt}
        price={margin.price}
      />

      <UnitsOf1Lot
        updater={updateMarginCalculator}
        className={`col-${elementSize}`}
        selectedInstrument={margin.selectedInstrument}
        unitsOfOneLot={margin.unitsOfOneLot}
        tradeSizeInfo={margin.tradeSizeInfo}
        calc="margin"
      />
    </Calculator>
  );
}

export default MarginCalculator;
