import { ChangeEventHandler } from "react";
import { onlyNum } from "../../../lib/funcs";
import InputGroup from "../InputGroup";
import { useLanguage } from "../../../lib/LanguageContext";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { updateProfitCalculator } from "../../../store/profit/actions";

interface IClosePriceInput {
  className?: string;
}

function ClosePriceInput({
  className,
}: IClosePriceInput) {
  const { languageData } = useLanguage();
  const dispatch = useDispatch();
  const profit = useSelector((state: RootState) => state.profit);

  const closePriceChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(updateProfitCalculator({ closePrice: onlyNum(e.target.value) }));
  };

  return (
    <div className={className || "col-12"}>
      <InputGroup
        type="text"
        label={languageData.close_price}
        value={profit.closePrice || ""}
        onChange={closePriceChanged}
      />
    </div>
  );
}

export default ClosePriceInput;
