// store.ts
import { configureStore } from '@reduxjs/toolkit';
import varReducer from './variables/varReducer';
import { combineReducers } from 'redux';
import lotsizeReducer from './lotsize/reducer';
import { depositCurrenciesReducer, instrumentsReducer } from './shared/reducer';
import marginReducer from './margin/reducer';
import pipvalueReducer from './pipvalue/reducer';
import profitReducer from './profit/reducer';

// const store = configureStore(configReducer);

const store = configureStore({
  reducer: combineReducers({ 
    variables: varReducer,
    profit: profitReducer,
    lotsize: lotsizeReducer,
    margin: marginReducer,
    pipvalue: pipvalueReducer,
    instruments: instrumentsReducer,
    depositCurrencies: depositCurrenciesReducer 
  }),
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
