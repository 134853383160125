import OptionGroup from "../OptionGroup";
import SelectGroup from "../SelectGroup";
import { useLanguage } from "../../../lib/LanguageContext";
import { genericTranslate } from "../../../lib/funcs";
import { useDispatch } from "react-redux";
import { updateProfitCalculator } from "../../../store/profit/actions";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface IPositionDirectionInput {
  className?: string;
}

function PositionDirectionInput({className}: IPositionDirectionInput) {
  const { languageData } = useLanguage();
  const positionDirections: IPositionDirection[] = [
    {
      value: "1",
      name: "Buy",
      desc: "Long",
    },
    {
      value: "-1",
      name: "Sell",
      desc: "Short",
    },
  ];

  const dispatch = useDispatch();
  const profit = useSelector((state: RootState) => state.profit);

  const changePositionDirection = (pd: string | number) => {
    const positionDirection =
      positionDirections[positionDirections.findIndex((d) => d.value === pd)];
    dispatch(updateProfitCalculator({ selectedPositionDirection: positionDirection }));
  };

  return !className || className === "col-12" ? (
    <div className="col-12">
      <OptionGroup
        label={`${languageData.position_direction}: `}
        options={positionDirections.map((pd) => ({
          value: pd.value,
          name: genericTranslate(languageData, pd.name),
          note: genericTranslate(languageData, pd.desc),
          selected:
            profit.selectedPositionDirection &&
            profit.selectedPositionDirection.value === pd.value,
        }))}
        setValue={changePositionDirection}
      />
    </div>
  ) : (
    <div className={className}>
      <SelectGroup
        label={languageData.position_direction}
        setValue={changePositionDirection}
        options={positionDirections.map((pd) => ({
          value: pd.value,
          name: pd.name,
          note: pd.desc,
          selected:
            profit.selectedPositionDirection &&
            profit.selectedPositionDirection.value === pd.value,
        }))}
      />
    </div>
  );
}

export default PositionDirectionInput;
