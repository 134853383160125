import {
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  useElementSize,
  useIsMobile,
  useShowDesc,
  useShowAds,
  useTextAlign,
  useTitle,
} from "../../lib/custom-hooks";
import AdLinks from "./AdLinks";
import { ToastContainer } from "react-toastify";
import LanguageSelector from "../LanguageSelector";

interface ICalculatorProps {
  wide?: boolean;
  noLine?: boolean;
  children: ReactNode;
}

function RateCalculator({
  noLine,
  children,
}: ICalculatorProps) {
  const [loading] = useState(false);
  const [wide, setWide] = useState(false);
  const elementSize = useElementSize();
  const showDesc = useShowDesc();
  const showAds = useShowAds(['forex', 'crypto']);
  const title = useTitle();
  const textAlign = useTextAlign();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (showDesc && elementSize < 6) setWide(true);
    else setWide(false);
  }, [showDesc, elementSize]);

  useEffect(() => {
    const calcEl = document.querySelector("#rate-calculator") as HTMLDivElement;

    const sendSizeToParent = () => {
      const href = document.location.href.split("/");
      const calcType = href[href.length - 1].split("?")[0];
      const w = calcEl.clientWidth;
      const h = calcEl.clientHeight;
      window.top?.postMessage(`IFrameSize|${calcType}|${w}X${h}`, "*");
    };

    document.addEventListener("DOMContentLoaded", sendSizeToParent, false);
    new ResizeObserver(sendSizeToParent).observe(calcEl);
    return () => {
      new ResizeObserver(sendSizeToParent).unobserve(calcEl);
      document.removeEventListener("DOMContentLoaded", sendSizeToParent, false);
    };
  }, []);

  const [windowInner, setWindowInner] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowInner(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
  });

  return (
    <>
      <LanguageSelector />
      <div
        id="rate-calculator"
        style={{
          width: windowInner < 768 ? "100%" : (wide ? "var(--wide-calculator-width)" : "var(--calculator-width)"),
          padding: windowInner < 768 ? "auto 2rem" : "",
        }}
        className={[
          noLine ? "no-borderline" : "",
          wide ? "wide" : "",
          isMobile ? "is-mobile" : "",
          "p-4"
        ]
          .filter((c) => c.length)
          .join(" ")}
      >
        {title ? (
          <h3 style={{ textAlign: textAlign || "left" }}>{title}</h3>
        ) : null}
        {loading ? (
          <div id="spinner-container">
            <div id="loading-spinner" />
          </div>
        ) : (
          <div className="row">
            {children}
            {showAds ? <AdLinks /> : ""}
          </div>
        )}
        <ToastContainer />
      </div>
    </>
  );
}

export default RateCalculator;
