import SelectGroup from "../SelectGroup";
import { useLanguage } from "../../../lib/LanguageContext";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface IDepositCurrencyInput {
  updater: (updates: Partial<any>) => any;
  selectedInstrument: string | undefined;
  selectedDepositCurrency: string | undefined;
  className?: string;
  depositableCurrencies: IDepositCurrency[];
}

function DepositCurrencyInput({
  selectedDepositCurrency,
  selectedInstrument,
  className,
  updater,
  depositableCurrencies
}: IDepositCurrencyInput) {

  const dispatch = useDispatch();
  const { languageData } = useLanguage();
  const depositCurrencies = useSelector((state: RootState) => state.depositCurrencies);

  const changeDepositCurrency = (dc: string | number) => {
    const depositCurrency = depositCurrencies.find((d) => d.GlobalCurrencyName === dc);
    dispatch(updater({ selectedDepositCurrency: depositCurrency?.GlobalCurrencyName || depositCurrencies[0]?.GlobalCurrencyName }));
  };

  if(!selectedInstrument) return null;

  return (
    <div className={className || "col-12"}>
      <SelectGroup
        hint={languageData.this_list_may_change_based_on_currency_pair}
        label={languageData.deposit_currency}
        options={depositableCurrencies.map((dc) => ({
          value: dc.GlobalCurrencyName,
          name: dc.GlobalCurrencyName,
          note: dc.CurrencyFullName,
          selected: selectedDepositCurrency === dc.GlobalCurrencyName,
        }))}
        setValue={changeDepositCurrency}
      />
    </div>
  );
}

export default DepositCurrencyInput;
