import { DEFAULT_MARGIN_STATE, OPERATIONS } from "../../lib/constants";
import { getInstrumentBySymbolName, getLatestDepositCurrencies, getLatestInstruments, setMarginState } from "../../lib/funcs";
import { updateUnitsOfOneLot } from "../shared/funcs";
import { MarginActionTypes, UPDATE_MARGIN_FIELDS } from "./actionTypes";

const updatePrices = (changed: Partial<IMarginFields>, updated: IMarginFields) => {
  let pInstrument: IInstrument | null = null;
  if(changed.hasOwnProperty("selectedInstrument") || changed.hasOwnProperty("selectedDepositCurrency") || !updated.priceInstrument) {
    if (
      !updated.selectedInstrument ||
      !updated.selectedDepositCurrency ||
      updated.selectedInstrument.startsWith(`${updated.selectedDepositCurrency}/`)
    ) {
      updated.priceInstrument = undefined;
    } else {
      const selectedInstrumentObj = getInstrumentBySymbolName(updated.selectedInstrument);
      if (updated.selectedInstrument.endsWith(`/${updated.selectedDepositCurrency}`)) {
        updated.priceInstrument = { SymbolName: selectedInstrumentObj.SymbolName, operation: OPERATIONS.MUL };
      } else {
        pInstrument = selectedInstrumentObj;
        let operation = OPERATIONS.MUL;

        getLatestInstruments().forEach((i: IInstrument) => {
          if (
            updated.selectedInstrument.startsWith(`${i.BaseCurrency}/`) &&
            updated.selectedDepositCurrency === i.QuoteCurrency
          ) {
            pInstrument = i;
            operation = OPERATIONS.MUL;
          }
          if (
            i.BaseCurrency === updated.selectedDepositCurrency &&
            updated.selectedInstrument.startsWith(`${i.QuoteCurrency}/`)
          ) {
            pInstrument = i;
            operation = OPERATIONS.DIV;
          }
        });
        if (pInstrument) {
          updated.priceInstrument = { SymbolName: pInstrument.SymbolName, operation };
        }
      }
    }
  }

  if(((changed.hasOwnProperty("selectedInstrument") || changed.hasOwnProperty("selectedDepositCurrency")) && !changed.hasOwnProperty("unitsOfOneLot")) || changed.priceUpdatedAt === 0) {
    pInstrument = pInstrument || getInstrumentBySymbolName(updated.selectedInstrument);
    updated.price = Number(pInstrument.SymbolRate.toFixed(6)).toString();
  }

  return updated;
}

const updateDepositableCurrencies = (changed: Partial<IMarginFields>, updated: IMarginFields) => {
  if(!changed.selectedInstrument && !changed.selectedDepositCurrency && updated.depositableCurrencies.length) return updated;

  let depositableCurrencies: IDepositCurrency[] = [];
  const depositCurrencies = getLatestDepositCurrencies();
  const instruments = getLatestInstruments();

  if (updated.selectedInstrument && depositCurrencies.length && instruments.length) {
    const filteredInstruments = instruments.filter(
      (ins) =>
        updated.selectedInstrument.startsWith(`${ins.BaseCurrency}/`) ||
        updated.selectedInstrument.startsWith(`${ins.QuoteCurrency}/`)
    );
    depositableCurrencies =
      depositCurrencies.filter(
        (dc) =>
          filteredInstruments.filter(
            (fi) =>
              fi.BaseCurrency === dc.GlobalCurrencyName ||
              fi.QuoteCurrency === dc.GlobalCurrencyName
          ).length
      );
  }

  if (!depositableCurrencies.some(dc => dc.GlobalCurrencyName === updated.selectedDepositCurrency)) {
    updated.selectedDepositCurrency = depositableCurrencies[0]?.GlobalCurrencyName;
  }

  updated.depositableCurrencies = depositableCurrencies;

  return updated;
}

const marginReducer = (state = DEFAULT_MARGIN_STATE, action: MarginActionTypes): IMarginFields => {
  switch (action.type) {
    case UPDATE_MARGIN_FIELDS: {

      let changed  = action.payload;
      let updated = { ...state, ...changed, priceUpdatedAt: Math.max(state.priceUpdatedAt || 0, changed.priceUpdatedAt || 0) };

      updated = updatePrices(changed, updated) as IMarginFields;
      updated = updateUnitsOfOneLot(changed, updated) as IMarginFields;
      updated = updateDepositableCurrencies(changed, updated) as IMarginFields;

      setMarginState(updated);

      return updated;
    }
    default:
      return state;
  }
};


export default marginReducer;
