import React from "react";
import FormGroup from "./FormGroup";
import SearchableSelectBox from "./SearchableSelectBox";

function SelectGroup({
  label,
  options,
  setValue,
  hint,
}: {
  hint?: string;
  label: string;
  options: ISelectOptions[];
  setValue: (value: string | number) => void;
}) {
  return (
    <FormGroup label={label} hint={hint}>
      <SearchableSelectBox
        label={label}
        options={options}
        setValue={setValue}
      />
    </FormGroup>
  );
}

export default SelectGroup;
