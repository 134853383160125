import NoteContainer from "./NoteContainer";

function TermsOfUse() {
  return (
    <NoteContainer title="Terms of Use">
      <p>By using this app, you agree to the following terms:</p>

      <p><strong>Free App with Ads:</strong> This app is free to use but includes advertising.</p>

      <p><strong>No Warranty:</strong>  The app is provided "as-is" and without warranties of any kind. We disclaim all warranties, express or implied, including accuracy, completeness, or fitness for a particular purpose.</p>
      
      <p><strong>Limitation of Liability:</strong> We are not liable for any damages arising from app use, including data loss, errors, or financial losses.</p>

      <p><strong>Your Use:</strong> You agree to use the app responsibly and in accordance with all applicable laws.</p>

      <p><strong>Termination:</strong> We reserve the right to terminate your access to the app for any reason.</p>

      <h6>Governing Law</h6>
      <p>These Terms shall be governed by and defined following the laws of Italy. Forex Calculators and yourself irrevocably consent that the courts of Italy shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these terms.</p>
      
      <h6>Changes to Terms:</h6>
      <p>We may update these terms at any time. By continuing to use the app, you agree to any revisions.</p>

      <h6>Contact Us</h6>
      <p>If a term does not make sense to you, let us know please: <a href="mailto:fxcalcsapps@gmail.com">fxcalcsapps@gmail.com</a></p>  
    </NoteContainer>
  );
}

export default TermsOfUse;