import { useLanguage } from "../../lib/LanguageContext";
import { useArrangeMode, useShowDesc } from "../../lib/custom-hooks";
import { onlyNum, splitAndRender } from "../../lib/funcs";

interface IWinRateCalc {
  winRate: any;
  setWinRate: React.Dispatch<React.SetStateAction<any>>;
  calculate: (ratio: string) => void;
  correct: (ratio: string) => string;
  className?: string;
}

function WinRateCalc({
  winRate,
  setWinRate,
  calculate,
  correct,
  className,
}: IWinRateCalc) {
  const { languageData } = useLanguage();
  const arrangeMode = useArrangeMode();
  const showDesc = useShowDesc();
  const { win_rate, win_desc } = languageData[`mode_${arrangeMode}`] || {};

  const handleCalculate = () => {
    const winRateInput = document.getElementById("winRate") as HTMLInputElement;
    if (winRateInput) {
      calculate(winRateInput.value);
    }
  }
  
  const handleInput = (e: any) => {
    const inputValue = correct(onlyNum(e.target.value));
    if (inputValue !== winRate.ratio) {
      setWinRate({...winRate, ratio: inputValue, result: ''});
    }
  }

  const ratioInput = (
    <input
      id="winRate"
      type="text"
      size={5}
      value={winRate.ratio}
      onChange={handleInput}
    />
  );

  const calculateButton = (
    <input
      type="submit"
      className="btn btn-primary"
      value={languageData.calculate || "Calculate"}
      onClick={handleCalculate}
    />
  );
  
  const winRateText = win_rate
    ? splitAndRender(win_rate, {
        "%CALCULATE_BUTTON%": calculateButton,
        "%WIN_RATE_PERCENTAGE_INPUT%": ratioInput,
        "%RISK_REWARD_RATIO_INPUT%": <span className="result">{winRate.result || ""}</span>,
      })
    : '';
  
  const winRateDesc = win_desc
    ? splitAndRender(win_desc, {
        "%WIN_RATE_PERCENTAGE%": <b>{(winRate.result && winRate.ratio) || "..."}</b>,
        "%RISK_REWARD_RATIO%": <b>{winRate.result || "..."}</b>,
      })
    : '';

  return (
    <div className={className || "col-12"}>
      <div className="form-group">
        {winRateText && <div className="inline-box">{winRateText}</div>}
        {showDesc && winRateDesc && <div className="desc">{winRateDesc}</div>}
      </div>
    </div>
  );
}

export default WinRateCalc;
