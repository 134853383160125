import { ProfitActionTypes, UPDATE_PROFIT_FIELDS } from "./actionTypes";
import { getProfitState } from "../../lib/funcs";
import { Dispatch } from "redux";

export const updateProfitCalculator = (updates: Partial<IProfitFields>): ProfitActionTypes => ({
  type: UPDATE_PROFIT_FIELDS,
  payload: updates
});

export const loadInitialProfitFields = async (dispatch: Dispatch<ProfitActionTypes>) => {
  try {
    let profitCalculatorFields: IProfitFields = getProfitState();
    dispatch(updateProfitCalculator(profitCalculatorFields || {}));
  } catch (error) {
    console.error('Error loading initial state from Chrome storage:', error);
  }
};