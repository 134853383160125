import NoteContainer from "./NoteContainer";

function Disclaimer() {
  return (
    <NoteContainer title="Disclaimer">
      <p>
        This app has no guarantees. 
        By using it, you agree to take full responsibility for any risks. 
        We don't promise the app is accurate or error-free, and aren't liable for any damages, injuries, or problems that may arise. 
        This includes issues with linked websites, security breaches, or content you encounter while using the app. 
        We also don't endorse any advertisements or third-party services you find within the app.
      </p>  
    </NoteContainer>
  );
}

export default Disclaimer;