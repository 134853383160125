import { SET_SAVED_FORM_INFO, SET_SETTINGS, VariableActionTypes } from "./varActionTypes";
import { getMobileAppSettings, getSavedFormInfo } from "../../lib/funcs";
import { Dispatch } from "redux";

export const setSettings = (settings: ISettings): VariableActionTypes => ({
  type: SET_SETTINGS,
  payload: settings
});

export const setSavedFormInfo = (formInfo: ISavedFormItem): VariableActionTypes => ({
  type: SET_SAVED_FORM_INFO,
  payload: formInfo
});

export const loadInitialSavedFormInfo = async (dispatch: Dispatch<VariableActionTypes>) => {
  try {
  } catch (error) {
    console.error('Error loading initial state from Chrome storage:', error);
  }
};

export const loadInitialVariables = async (dispatch: Dispatch<VariableActionTypes>) => {
  try {
    const settings = getMobileAppSettings();
    dispatch(setSettings(settings));
    if(settings.rememberChanges) {
      dispatch(setSavedFormInfo(getSavedFormInfo()));
    } else {
      dispatch(setSavedFormInfo({}));
    }
  } catch (error) {
    console.error('Error loading initial state from Chrome storage:', error);
  }
};