import { ChangeEventHandler } from "react";
import { genStopLoss, getInstrumentBySymbolName, getPipsByStopLoss, onlyNum, stringToId } from "../../../lib/funcs";
import InputGroup from "../InputGroup";
import FormGroup from "../FormGroup";
import { useElementSize } from "../../../lib/custom-hooks";
import InnerSwitcher from "./InnerSwitcher";
import { useLanguage } from "../../../lib/LanguageContext";
import { DEFAULT_ONE_PIP, STOPLOSS_TYPES } from "../../../lib/constants";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { updateLotsizeCalculator } from "../../../store/lotsize/actions";

function StopLossInput() {
  const elementSize = useElementSize();
  const dispatch = useDispatch();
  const { languageData } = useLanguage();
  const lotsize = useSelector((state: RootState) => state.lotsize);

  const stopLossChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(updateLotsizeCalculator({ stopLoss: onlyNum(e.target.value) }));
  };

  const stopLossTypeChanged = (value: string | number, lotsize: ILotsizeFields) => {
    const slt = Number(value);
    if (lotsize.stopLossType === slt) return;

    if (slt === STOPLOSS_TYPES.PIPS) {
      return dispatch(updateLotsizeCalculator({ 
        openPrice: "",
        stopLossType: slt,
        stopLoss: getPipsByStopLoss(lotsize.openPrice, typeof lotsize.onePip === "undefined" ? DEFAULT_ONE_PIP : lotsize.onePip, lotsize.stopLoss).toString()
      }));
    }
    const si = getInstrumentBySymbolName(lotsize.selectedInstrument)
    const price = si?.SymbolRate || "1.0";
    
    return dispatch(updateLotsizeCalculator({ 
      openPrice: price.toString(),
      stopLossType: slt,
      stopLoss: Number(genStopLoss(Number(price), Number(lotsize.onePip), Number(lotsize.stopLoss)).toFixed(6)).toString()
    }));
  };

  const openPriceChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(updateLotsizeCalculator({ openPrice: onlyNum(e.target.value) }));
  };

  let className = `col-${elementSize}`;
  if (lotsize.stopLossType !== STOPLOSS_TYPES.CURRENCY)
    className = `col-${Math.min(elementSize * 2, 12)}`;

  return (
    <>
      {lotsize.stopLossType === STOPLOSS_TYPES.CURRENCY ? (
        <div className={className}>
          <InputGroup
            type="text"
            label={languageData.open_price}
            value={lotsize.openPrice}
            onChange={openPriceChanged}
          />
        </div>
      ) : null}
      <div className={className}>
        <FormGroup
          label={`${languageData.stop_loss} (${
            lotsize.stopLossType === STOPLOSS_TYPES.PIPS
              ? languageData.pips
              : languageData.price
          })`}
        >
          <input
            id={stringToId(
              `${languageData.stop_loss} (${
                lotsize.stopLossType === STOPLOSS_TYPES.PIPS
                  ? languageData.pips
                  : languageData.price
              })`
            )}
            type="text"
            onChange={stopLossChanged}
            value={lotsize.stopLoss}
          />
          <InnerSwitcher
            innerSwitcher={{
              name: "sltype",
              option1: {
                isChecked: lotsize.stopLossType === STOPLOSS_TYPES.PIPS,
                onClick: () => stopLossTypeChanged(STOPLOSS_TYPES.PIPS, lotsize),
                text: languageData.pips,
              },
              option2: {
                isChecked: lotsize.stopLossType === STOPLOSS_TYPES.CURRENCY,
                onClick: () => stopLossTypeChanged(STOPLOSS_TYPES.CURRENCY, lotsize),
                text: languageData.price,
              },
            }}
          />
        </FormGroup>
      </div>
    </>
  );
}

export default StopLossInput;
