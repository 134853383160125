import { ChangeEventHandler, FocusEventHandler, useState, useEffect, useCallback } from "react";
import InputGroup from "../InputGroup";
import { getInstrumentBySymbolName, getLanguageDirection } from "../../../lib/funcs";
import { useLanguage } from "../../../lib/LanguageContext";
import { useIsMobile, useShowEsma } from "../../../lib/custom-hooks";
import styles from "./LeverageInput.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { updateMarginCalculator } from "../../../store/margin/actions";
import { useDispatch } from "react-redux";

interface ILeverageInput {
  className?: string;
}

function LeverageInput({ className }: ILeverageInput) {
  const settings = useSelector((state: RootState) => state.variables.settings);
  const { languageData } = useLanguage();
  const showEsma = useShowEsma();
  const [esma, setEsma] = useState(false);
  const [addition, setAddition] = useState(":1");
  const isMobile = useIsMobile();
  const margin = useSelector((state: RootState) => state.margin);

  const dispatch = useDispatch();

  useEffect(() => {
    if(isMobile) {
      setEsma(settings.ESMALeverage);
    }
  }, [isMobile, settings.ESMALeverage]);

  const correct = useCallback((value: string) => {
    const numericValue = parseInt(value, 10);

    if (!numericValue || isNaN(numericValue)) return "";

    const maxLeverageMap: Record<string, number> = {
      'crypto': 2,
      'forex': 20,
      'eurusd': 30,
      'usdjpy': 30,
      'gbpusd': 30,
      'usdchf': 30,
      'usdcad': 30,
      'audusd': 30,
      'nzdusd': 30,
      'xagusd': 10,
      'xageur': 10,
      'xagaud': 10,
    };
    
    const symbol = margin.selectedInstrument.toLowerCase() ?? '';
    const market = getInstrumentBySymbolName(margin.selectedInstrument).Market.toLowerCase();
    
    const maxLeverage = maxLeverageMap[symbol] || maxLeverageMap[market];

    if (maxLeverage && numericValue >= maxLeverage) {
      return maxLeverage.toString();
    }

    return numericValue.toString();
  }, [margin.selectedInstrument, getInstrumentBySymbolName(margin.selectedInstrument)?.Market]);

  const leverageChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = esma ? correct(e.target.value) : e.target.value;
    return dispatch(updateMarginCalculator({ leverage: value }));
  };

  const leverageBlurred: FocusEventHandler = (e) => {
    dispatch(updateMarginCalculator({ leverage: (Number(margin.leverage.split(":")[0].replace(/\D/g, "") || "0") || 100) + "" }));
    setAddition(":1");
  };

  const leverageFocused: FocusEventHandler<HTMLInputElement> = (e) => {
    setAddition("");
  };

  useEffect(() => {
    if (esma) {
      const newLeverage = correct(margin.leverage);
      dispatch(updateMarginCalculator({ leverage: newLeverage }))
    }
  }, [esma, margin.leverage, correct, dispatch]);

  const esmaCheckbox = (
    <label className={[
        styles.additionalLabel,
        styles[getLanguageDirection(languageData)],
      ].join(" ")}
    >
      <input
        type="checkbox"
        onChange={() => setEsma((prevEsma) => !prevEsma)}
        checked={esma}
      />
      <span className={styles.checkmark}></span>
      <span className={styles.label}>ESMA Rules</span>
    </label>
  );

  return (
    <div className={className || "col-12"}>
      <InputGroup
        type="text"
        label={languageData.leverage}
        additionallabel={(showEsma && esmaCheckbox) || ''}
        value={margin.leverage + addition}
        onFocus={leverageFocused}
        onBlur={leverageBlurred}
        onChange={leverageChanged}
        hint={(showEsma && languageData.leverage_restrictions_of_eu) || ''}
      />
    </div>
  );
}

export default LeverageInput;
