import { MarginActionTypes, UPDATE_MARGIN_FIELDS } from "./actionTypes";
import { getMarginState } from "../../lib/funcs";
import { Dispatch } from "redux";

export const updateMarginCalculator = (updates: Partial<IMarginFields>): MarginActionTypes => ({
  type: UPDATE_MARGIN_FIELDS,
  payload: updates
});

export const loadInitialMarginFields = async (dispatch: Dispatch<MarginActionTypes>) => {
  try {
    let marginCalculatorFields: IMarginFields = getMarginState();
    dispatch(updateMarginCalculator(marginCalculatorFields || {}));
  } catch (error) {
    console.error('Error loading initial state from Chrome storage:', error);
  }
};