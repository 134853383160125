import { ChangeEventHandler } from "react";
import { getInstrumentBySymbolName, onlyNum, saveLotUnits } from "../../../lib/funcs";
import InputGroup from "../InputGroup";
import { useLanguage } from "../../../lib/LanguageContext";
import { useDispatch } from "react-redux";

interface IUnitsOf1Lot {
  updater: (updates: any) => any;
  unitsOfOneLot: string | number;
  selectedInstrument: string;
  className?: string;
  calc: ICalculators;
  tradeSizeInfo?: ITradeSizeInfo;
}

function UnitsOf1Lot({
  updater,
  unitsOfOneLot,
  selectedInstrument,
  className,
}: IUnitsOf1Lot) {
  const { languageData } = useLanguage();
  const dispatch = useDispatch();

  const unitsOfOneLotChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    const lu = onlyNum(e.target.value);
    if(selectedInstrument)
      saveLotUnits(selectedInstrument, lu, dispatch);
    
    dispatch(updater({ unitsOfOneLot: lu }));
  };

  return selectedInstrument ? (
    <div className={className || "col-12"}>
      <InputGroup
        type="text"
        hint={
          getInstrumentBySymbolName(selectedInstrument).Market === "Crypto"
            ? languageData.this_may_vary_in_your_broker
            : ""
        }
        label={languageData.units_of_1_lot}
        value={unitsOfOneLot}
        onChange={unitsOfOneLotChanged}
      />
    </div>
  ) : (
    <></>
  );
}

export default UnitsOf1Lot;
