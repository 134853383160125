import { DEFAULT_PIPVALUE_STATE, OPERATIONS } from "../../lib/constants";
import { getInstrumentBySymbolName, getLatestDepositCurrencies, getLatestInstruments, setPipvalueState } from "../../lib/funcs";
import { updateOnePips, updateUnitsOfOneLot } from "../shared/funcs";
import { PipvalueActionTypes, UPDATE_PIPVALUE_FIELDS } from "./actionTypes";

const updatePrices = (changed: Partial<IPipvalueFields>, updated: IPipvalueFields) => {
  let pInstrument: IInstrument | null = null;
  if (changed.hasOwnProperty("selectedInstrument") || changed.hasOwnProperty("selectedDepositCurrency") || !updated.priceInstrument) {
    if (
      !updated.selectedInstrument ||
      !updated.selectedDepositCurrency ||
      updated.selectedInstrument.endsWith(`/${updated.selectedDepositCurrency}`)
    ) {
      updated.priceInstrument = undefined;
    } else {
      const selectedInstrumentObj = getInstrumentBySymbolName(updated.selectedInstrument);
      if (updated.selectedInstrument.startsWith(`${updated.selectedDepositCurrency}/`)) {
        updated.priceInstrument = { SymbolName: selectedInstrumentObj.SymbolName, operation: OPERATIONS.MUL };
      } else {
        pInstrument = selectedInstrumentObj;
        let operation = OPERATIONS.MUL;

        getLatestInstruments().forEach((i: IInstrument) => {
          if (
            updated.selectedInstrument.endsWith(`/${i.QuoteCurrency}`) &&
            updated.selectedDepositCurrency === i.BaseCurrency
          ) {
            pInstrument = i;
            operation = OPERATIONS.DIV;
          }
          if (
            i.QuoteCurrency === updated.selectedDepositCurrency &&
            updated.selectedInstrument.endsWith(`/${i.BaseCurrency}`)
          ) {
            pInstrument = i;
            operation = OPERATIONS.MUL;
          }
        });
        if (pInstrument) {
          updated.priceInstrument = { SymbolName: pInstrument.SymbolName, operation };
        }
      }
    }
  }
  
  if(((changed.hasOwnProperty("selectedInstrument") || changed.hasOwnProperty("selectedDepositCurrency")) && !changed.hasOwnProperty("unitsOfOneLot")) || changed.priceUpdatedAt === 0) {
    pInstrument = pInstrument || getInstrumentBySymbolName(updated.selectedInstrument);
    updated.price = Number(pInstrument.SymbolRate.toFixed(6)).toString();
  }

  return updated;
}

const updateDepositableCurrencies = (changed: Partial<IPipvalueFields>, updated: IPipvalueFields) => {
  if (!changed.selectedInstrument && !changed.selectedDepositCurrency && updated.depositableCurrencies.length) return updated;
  const depositCurrencies = getLatestDepositCurrencies();
  const instruments = getLatestInstruments();

  let depositableCurrencies: IDepositCurrency[] = [];
  if (updated.selectedInstrument && depositCurrencies.length) {
    const filteredInstruments = instruments.filter(
      (ins) =>
        updated.selectedInstrument.endsWith(`/${ins.BaseCurrency}`) ||
        updated.selectedInstrument.endsWith(`/${ins.QuoteCurrency}`)
    );
    depositableCurrencies =
      depositCurrencies.filter(
        (dc) =>
          filteredInstruments.filter(
            (fi) =>
              fi.BaseCurrency === dc.GlobalCurrencyName ||
              fi.QuoteCurrency === dc.GlobalCurrencyName
          ).length
      );
  }

  if (!depositableCurrencies.some(dc => dc.GlobalCurrencyName === updated.selectedDepositCurrency)) {
    updated.selectedDepositCurrency = depositableCurrencies[0]?.GlobalCurrencyName;
  }

  updated.depositableCurrencies = depositableCurrencies;

  return updated;
}

const pipvalueReducer = (state = DEFAULT_PIPVALUE_STATE, action: PipvalueActionTypes): IPipvalueFields => {
  switch (action.type) {
    case UPDATE_PIPVALUE_FIELDS: {

      let changed = action.payload;
      let updated = { ...state, ...changed, priceUpdatedAt: Math.max(state.priceUpdatedAt || 0, changed.priceUpdatedAt || 0) };

      updated = updatePrices(changed, updated) as IPipvalueFields;
      updated = updateOnePips(changed, updated) as IPipvalueFields;
      updated = updateUnitsOfOneLot(changed, updated) as IPipvalueFields;
      updated = updateDepositableCurrencies(changed, updated) as IPipvalueFields;

      setPipvalueState(updated);

      return updated;
    }
    default:
      return state;
  }
};


export default pipvalueReducer;
