import "../styles/navigation.scss";
import LanguageSelector from "./LanguageSelector";

export default function NavigationBar() {
  return (
    <nav className="">
      <LanguageSelector />
    </nav>
  );
}
