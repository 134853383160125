import styles from "./AdLinks.module.scss";
import { useArrangeMode, useIsMobile, useIsPC, useShowAds } from '../../lib/custom-hooks';
import { useLocation } from "react-router";
import { useEffect, useState } from "react";

function AdLinks() {
  const isMobile = useIsMobile();
  const isPC = useIsPC();
  const arrangeMode = useArrangeMode();
  const showAdsForex = useShowAds(['forex']);
  const showAdsCrypto = useShowAds(['crypto']);
  const showAllAds = useShowAds(['1']);
  const location = useLocation();
  const calcName = location.pathname.replace(/^\/+/g, '');
  const [colSize, setColSize] = useState((arrangeMode === 3 && calcName !== 'rrwinrate') || (showAdsForex && showAdsCrypto) || showAllAds ? 'col-6' : 'col-12');
  useEffect(() => {
    const calcName = location.pathname.replace(/^\/+/g, '');
    const colSize = (arrangeMode === 3 && calcName !== 'rrwinrate') || (showAdsForex && showAdsCrypto) || showAllAds ? 'col-6' : 'col-12';
    setColSize(colSize);
  }, [arrangeMode, location.pathname, showAdsCrypto, showAdsForex, showAllAds]);

  return <div className={[styles.adLinks, showAllAds ? styles.styled : ""].join(" ")}>
    <div className="row">
      {(showAllAds || showAdsForex) && (
        <div className={colSize}>
          <a
            className={showAllAds ? styles.styled : ""}
            href={showAllAds ? "https://www.ifcmarkets.com/441158/en" : "https://ifcmarkets.click"}
            target="_blank"
            rel="noreferrer"
          >
            Trade Forex with IFC Markets
          </a>
        </div>
      )}
      {(showAllAds || showAdsCrypto) && (
        <div className={colSize}>
          <a
            className={showAllAds ? styles.styled : ""}
            href="https://www.kucoin.com/r/af/rMBJY17"
            target="_blank"
            rel="noreferrer"
          >
            Trade Crypto with Kucoin {isMobile || isPC ? (<span> Exchange </span>) : null}
          </a>
        </div>
      )}
    </div>
  </div>
}

export default AdLinks;