import { useState } from 'react';
import { useLanguage } from '../../lib/LanguageContext';
import { useArrangeMode, useShowDesc } from '../../lib/custom-hooks';
import { onlyNum, splitAndRender } from "../../lib/funcs";

interface IRiskRewardWinRateCalc {
  riskReward: any;
  winRate: any;
  setRiskReward: React.Dispatch<React.SetStateAction<any>>;
  setWinRate: React.Dispatch<React.SetStateAction<any>>;
  calculateRisk: (ratio: string) => void;
  calculateWin: (ratio: string) => void;
  correctRisk: (ratio: string) => void;
  correctWin: (ratio: string) => void;
  className?: string;
}

function RiskRewardWinRateCalc({
  riskReward,
  winRate,
  setRiskReward,
  setWinRate,
  calculateRisk,
  calculateWin,
  correctRisk,
  correctWin,
  className,
}: IRiskRewardWinRateCalc) {
  const { languageData } = useLanguage();
  const arrangeMode = useArrangeMode();
  const showDesc = useShowDesc();
  const { risk_desc, win_desc } = languageData[`mode_${arrangeMode}`] || {};

  const [isRiskFirst, setIsRiskFirst] = useState<boolean>(true);

  const handleToggle = () => {
    setIsRiskFirst((prev) => !prev);
    setRiskReward({ ratio: '', result: '' });
    setWinRate({ ratio: '', result: '' });
  };

  const handleCalculate = () => {
    return isRiskFirst ? calculateRisk(riskReward.ratio) : calculateWin(winRate.ratio);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = onlyNum(e.target.value);
    const correctedValue = isRiskFirst ? correctRisk(inputValue) : correctWin(inputValue);
  
    if (correctedValue !== (isRiskFirst ? riskReward.ratio : winRate.ratio)) {
      isRiskFirst
        ? setRiskReward({ ...riskReward, ratio: correctedValue, result: '' })
        : setWinRate({ ...winRate, ratio: correctedValue, result: '' });
    }
  };

  const renderInput = (isRisk: boolean) => {
    const disabled = isRisk !== isRiskFirst;
    const input = (
      <input
        type="text"
        size={5}
        value={isRisk
          ? (isRiskFirst ? riskReward.ratio : winRate.result)
          : (isRiskFirst ? riskReward.result : winRate.ratio)}
        onChange={handleChange}
        disabled={disabled}
        className={disabled ? "bold" : ""}
      />
    );
  
    return (
      <div className="col-12 mb-4">
        <div className="row">
          <div className="col-6">
            {isRiskFirst
              ? (isRisk ? languageData.risk_reward_ratio : languageData.win_rate_breakeven)
              : (isRisk ? languageData.risk_reward_breakeven : languageData.win_rate) }
          </div>
          <div className="col-6">
            <span className="input-addon">{isRisk && '1: '}</span>
            {input}
            <span className="input-addon">{!isRisk && ' %'}</span>
          </div>
        </div>
      </div>
    );
  };

  const description = isRiskFirst ? risk_desc : win_desc;
  const renderedDescription = showDesc && description
  ? splitAndRender(description, {
      '%RISK_REWARD_RATIO%': (
        <span className="bold">
          {isRiskFirst
            ? (riskReward.result && riskReward.ratio) || '...'
            : winRate.result || '...'}
        </span>
      ),
      '%WIN_RATE_PERCENTAGE%': (
        <span className="bold">
          {isRiskFirst
            ? riskReward.result || '...'
            : (winRate.result && winRate.ratio) || '...'}
        </span>
      ),
    })
  : null;

  return (
    <>
      <div className={className || "col-12"}>
        {renderInput(isRiskFirst)}

        <div className="col-6 mb-4">
          <div className="form-group">
            <button className="btn btn-round-sm" onClick={handleToggle}>
              <div className="arrow-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="24"
                  fill="none"
                  stroke="var(--primary)"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="toggle-icon"
                >
                  <line x1="12" y1="5" x2="12" y2="19" />
                  <polyline points="5 12 12 5 19 12" />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="24"
                  fill="none"
                  stroke="var(--primary)"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="toggle-icon"
                >
                  <line x1="12" y1="19" x2="12" y2="5" />
                  <polyline points="19 12 12 19 5 12" />
                </svg>
              </div>
            </button>
          </div>
        </div>

        {renderInput(!isRiskFirst)}

        <div className="col-12">
          <div className="form-group">
            <input
              type="submit"
              className="btn btn-primary btn-block"
              value={languageData.calculate || "Calculate"}
              onClick={handleCalculate}
            />
          </div>
        </div>
      </div>
      {renderedDescription && (
        <div className="col-12">
          {renderedDescription}
        </div>
      )}
    </>
  );
}

export default RiskRewardWinRateCalc;
