import { ChangeEventHandler } from "react";
import { onlyNum } from "../../../lib/funcs";
import InputGroup from "../InputGroup";
import { useLanguage } from "../../../lib/LanguageContext";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { updateProfitCalculator } from "../../../store/profit/actions";

interface IOpenPriceInput {
  className?: string;
}

function OpenPriceInput({
  className,
}: IOpenPriceInput) {
  const { languageData } = useLanguage();
  const dispatch = useDispatch();
  const profit = useSelector((state: RootState) => state.profit);

  const openPriceChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(updateProfitCalculator({ openPrice: onlyNum(e.target.value) }));
  };

  return (
    <div className={className || "col-12"}>
      <InputGroup
        type="text"
        label={languageData.open_price}
        value={profit.openPrice || ""}
        onChange={openPriceChanged}
      />
    </div>
  );
}

export default OpenPriceInput;
