import styles from './InnerSwitcher.module.scss';

interface IInnerSwitcherOption {
  isChecked: boolean;
  onClick: (e: any) => void;
  text: string;
}

interface IInnerSwitcher {
  name: string;
  option1: IInnerSwitcherOption;
  option2: IInnerSwitcherOption;
}

function InnerSwitcher({ innerSwitcher }: { innerSwitcher: IInnerSwitcher }) {
  return (
    <div className={styles.innerSwitcher}>
      <label className={innerSwitcher.option1.isChecked ? styles.checked : ''}>
        <input
          type="radio"
          name={innerSwitcher.name}
          defaultChecked={innerSwitcher.option1.isChecked}
          onClick={innerSwitcher.option1.onClick}
        /> {innerSwitcher.option1.text}
      </label>
      <label className={innerSwitcher.option2.isChecked ? styles.checked : ''}>
        <input
          type="radio"
          name={innerSwitcher.name}
          defaultChecked={innerSwitcher.option2.isChecked}
          onClick={innerSwitcher.option2.onClick}
        /> {innerSwitcher.option2.text}
      </label>
    </div>
  );
}

export default InnerSwitcher;
