import { DEFAULT_PROFIT_STATE, DEFAULT_STOP_LOSS, OPERATIONS } from "../../lib/constants";
import { getInstrumentBySymbolName, getLatestDepositCurrencies, getLatestInstruments, setProfitState } from "../../lib/funcs";
import { updateUnitsOfOneLot } from "../shared/funcs";
import { ProfitActionTypes, UPDATE_PROFIT_FIELDS } from "./actionTypes";

const updatePrices = (changed: Partial<IProfitFields>, updated: IProfitFields) => {
  let pInstrument: IInstrument | null = null;
  if (changed.hasOwnProperty("selectedInstrument") || changed.hasOwnProperty("selectedDepositCurrency") || !updated.priceInstrument) {
    if (
      !updated.selectedInstrument ||
      !updated.selectedDepositCurrency ||
      updated.selectedInstrument.endsWith(`/${updated.selectedDepositCurrency}`)
    ) {
      updated.priceInstrument = undefined;
    } else {
      const selectedInstrumentObj = getInstrumentBySymbolName(updated.selectedInstrument);
      if (updated.selectedInstrument.startsWith(`${updated.selectedDepositCurrency}/`)) {
        updated.priceInstrument = { SymbolName: selectedInstrumentObj.SymbolName, operation: OPERATIONS.MUL };
      } else {
        pInstrument = selectedInstrumentObj;
        let operation = OPERATIONS.MUL;

        getLatestInstruments().forEach((i: IInstrument) => {
          if (
            updated.selectedInstrument.endsWith(`/${i.QuoteCurrency}`) &&
            updated.selectedDepositCurrency === i.BaseCurrency
          ) {
            pInstrument = i;
            operation = OPERATIONS.DIV;
          }
          if (
            i.QuoteCurrency === updated.selectedDepositCurrency &&
            updated.selectedInstrument.endsWith(`/${i.BaseCurrency}`)
          ) {
            pInstrument = i;
            operation = OPERATIONS.MUL;
          }
        });
        if (pInstrument) {
          updated.priceInstrument = { SymbolName: pInstrument.SymbolName, operation };
        }
      }
    }
  }

  if(((changed.hasOwnProperty("selectedInstrument") || changed.hasOwnProperty("selectedDepositCurrency")) && !changed.hasOwnProperty("unitsOfOneLot")) || changed.priceUpdatedAt === 0) {
    pInstrument = pInstrument || getInstrumentBySymbolName(updated.selectedInstrument);
    updated.price = Number(pInstrument.SymbolRate.toFixed(6)).toString();
  }

  return updated;
}

const updateDepositableCurrencies = (changed: Partial<IProfitFields>, updated: IProfitFields) => {
  if (!changed.selectedInstrument && !changed.selectedDepositCurrency && updated.depositableCurrencies.length) return updated;
  const depositCurrencies = getLatestDepositCurrencies();
  const instruments = getLatestInstruments();

  let depositableCurrencies: IDepositCurrency[] = [];
  if (!updated.selectedInstrument || !depositCurrencies.length) {
    depositableCurrencies = [];
  } else {
    const filteredInstruments = instruments.filter(
      (ins) =>
        updated.selectedInstrument.endsWith(`/${ins.BaseCurrency}`) ||
        updated.selectedInstrument.endsWith(`/${ins.QuoteCurrency}`)
    );
    depositableCurrencies = depositCurrencies.filter(
      (dc) =>
        filteredInstruments.filter(
          (fi) =>
            fi.BaseCurrency === dc.GlobalCurrencyName ||
            fi.QuoteCurrency === dc.GlobalCurrencyName
        ).length
    );
  }

  if (!depositableCurrencies.some(dc => dc.GlobalCurrencyName === updated.selectedDepositCurrency)) {
    updated.selectedDepositCurrency = depositableCurrencies[0]?.GlobalCurrencyName;
  }

  updated.depositableCurrencies = depositableCurrencies;

  return updated;
}

const updateOpenAndClosePrice = (changed: Partial<IProfitFields>, updated: IProfitFields) => {
  if (changed.selectedInstrument || typeof updated.openPrice === "undefined" || typeof updated.closePrice === "undefined") {
    const selectedInstrumentObj = getInstrumentBySymbolName(updated.selectedInstrument);
    const x = selectedInstrumentObj.PipSize * DEFAULT_STOP_LOSS;
    updated.openPrice = Number(selectedInstrumentObj.SymbolRate.toFixed(6)).toString();
    updated.closePrice = Number((Number(updated.openPrice as string) + Number(updated.selectedPositionDirection.value) * x).toFixed(6)).toString();
  }
  return updated;
}

const profitReducer = (state = DEFAULT_PROFIT_STATE, action: ProfitActionTypes): IProfitFields => {
  switch (action.type) {
    case UPDATE_PROFIT_FIELDS: {

      let changed = action.payload;
      let updated = { ...state, ...changed, priceUpdatedAt: Math.max(state.priceUpdatedAt || 0, changed.priceUpdatedAt || 0) };

      updated = updatePrices(changed, updated) as IProfitFields;
      updated = updateUnitsOfOneLot(changed, updated) as IProfitFields;
      updated = updateDepositableCurrencies(changed, updated) as IProfitFields;
      updated = updateOpenAndClosePrice(changed, updated);

      setProfitState(updated);

      return updated;
    }
    default:
      return state;
  }
};


export default profitReducer;
