import { ReactNode, useEffect, useState } from "react";
import MarginCalculator from "./MarginCalculator";
import LotSizeCalculator from "./LotSizeCalculator";
import PipValueCalculator from "./PipValueCalculator";
import ProfitCalculator from "./ProfitCalculator";
import styles from "./Home.module.scss";
import { useIsMobile } from "../lib/custom-hooks";
import NavigationBar from "./NavigationBar";
import SettingsMenu from './global/SettingsMenu';
import { loadInitialVariables } from "../store/variables/varActions";
import { useDispatch } from "react-redux";

function Home() {
  const dispatch = useDispatch();
  let act = "Lot Size";
  const [tabs, setTabs] = useState<{ [key: string]: ReactNode }>({
    "Lot Size": <LotSizeCalculator noLine />,
    "Margin": <MarginCalculator noLine />,
    "Pip Value": <PipValueCalculator noLine />,
    "Profit/Loss": <ProfitCalculator noLine />,
  });
  try {
    act = localStorage.getItem("active-tab") || "Lot Size";
  } catch (e) {}
  const [activeTab, setActiveTab] = useState(act);
  useEffect(() => {
    try {
      localStorage.setItem("active-tab", activeTab);
    } catch (e) {
      console.error("can't save current tab");
    }
  }, [activeTab]);

  const isMobile = useIsMobile();

  useEffect(() => {
    if(isMobile) {
      loadInitialVariables(dispatch);
    }
  }, [isMobile]);

  return (
    <div className={[styles.Home, isMobile ? styles.Mobile : ""].join(" ")}>
      {/* {isMobile ? tabs[activeTab] : null} */}
      <ul className={styles.Tabs}>
        {isMobile ? (
          <li className={styles.menuTab}>
            <SettingsMenu />
          </li>
        ) : null}
        {Object.keys(tabs).map((k) => (
          <li
            key={k}
            className={k === activeTab ? styles.active : ""}
            onClick={() => setActiveTab(k)}
          >
            {k}
          </li>
        ))}
      </ul>
      <div className={styles.main}>
        <NavigationBar />
        {tabs[activeTab]}
      </div>
    </div>
  );
}

export default Home;
