import React, { useEffect, useMemo, useState } from "react";
import Calculator from "./global/Calculator";
import ClosePriceInput from "./global/inputs/ClosePriceInput";
import DepositCurrencyInput from "./global/inputs/DepositCurrencyInput";
import InstrumentInput from "./global/inputs/InstrumentInput";
import OpenPriceInput from "./global/inputs/OpenPriceInput";
import PositionDirectionInput from "./global/inputs/PositionDirectionInput";
import PriceInput from "./global/inputs/PriceInput";
import UnitsOf1Lot from "./global/inputs/UnitsOf1Lot";
import {
  useElementSize,
} from "../lib/custom-hooks";
import { depositCurrencyMarket, formatResultNum } from "../lib/funcs";
import TradeSizeInput from "./global/inputs/TradeSizeInput";
import { OPERATIONS } from "../lib/constants";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { loadInitialProfitFields, updateProfitCalculator } from "../store/profit/actions";

function ProfitCalculator(props: { noLine?: boolean }) {
  const defaultResult = useMemo(() => ({ name: "Profit/Loss", result: "----" }), []);
  const elementSize = useElementSize();
  const [result, setResult] = useState<IResult>([defaultResult]);

  const profit = useSelector((state: RootState) => state.profit);

  const calculate: React.MouseEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    let result: string = "";
    if (!profit.selectedInstrument)
      return setResult([{ name: "Error", result: "No Instrument Selected" }]);
    if (!profit.selectedDepositCurrency)
      return setResult([
        { name: "Error", result: "No Deposit Currency Selected" },
      ]);
    if (!profit.openPrice)
      return setResult([{ name: "Error", result: "Please Enter Open Price" }]);
    if (!profit.closePrice)
      return setResult([{ name: "Error", result: "Please Enter Close Price" }]);
    if (!profit.tradeSizeInfo?.value || !Number(profit.tradeSizeInfo?.value))
      return setResult([
        {
          name: "Error",
          result: "Trade Size can't be " + (profit.tradeSizeInfo?.value || "empty"),
        },
      ]);
    if (!profit.selectedPositionDirection)
      return setResult([
        { name: "Error", result: "Please Select Position Direction" },
      ]);
    if (!profit.unitsOfOneLot || !Number(profit.unitsOfOneLot))
      return setResult([
        {
          name: "Error",
          result: "Units of 1 lot can't be " + (profit.unitsOfOneLot || "empty"),
        },
      ]);

    const fractionCount =
      depositCurrencyMarket("selectedDepositCurrency") === "Forex"
        ? 2
        : (profit.price + "").split(".")[0].length + 2;

    let tSize =
      profit.tradeSizeInfo.base === "Units"
        ? profit.tradeSizeInfo.value
        : Number(profit.tradeSizeInfo.value) * Number(profit.unitsOfOneLot);

    const r1 =
      Number(tSize) *
      (profit.selectedPositionDirection.name === "Buy"
        ? Number(profit.closePrice) - Number(profit.openPrice)
        : Number(profit.openPrice) - Number(profit.closePrice));
    if (profit.selectedInstrument.startsWith(`${profit.selectedDepositCurrency}/`)) {
      result =
        formatResultNum(r1 / Number(profit.price), fractionCount) +
        " " +
        profit.selectedDepositCurrency;
    } else if (profit.selectedInstrument.endsWith(`/${profit.selectedDepositCurrency}`)) {
      result =
        formatResultNum(r1, fractionCount) +
        " " +
        profit.selectedDepositCurrency;
    } else {
      if (profit.priceInstrument?.operation === OPERATIONS.MUL) {
        result =
          formatResultNum(r1 * Number(profit.price), fractionCount) +
          " " +
          profit.selectedDepositCurrency;
      } else {
        result =
          formatResultNum(r1 / Number(profit.price), fractionCount) +
          " " +
          profit.selectedDepositCurrency;
      }
    }
    setResult([{ name: "Profit/Loss", result }]);
  };

  useEffect(() => {
    setResult([defaultResult]);
  }, [
    defaultResult,
    profit
  ]);

  return (
    <Calculator
      noLine={props.noLine}
      result={result}
      calculate={calculate}
      updater={updateProfitCalculator}
      // initializer={loadInitialProfitFields}
    >
      <InstrumentInput
        className={`col-${Math.min(12, 2 * elementSize)}`}
        updater={updateProfitCalculator}
        selectedMarkets={profit.selectedMarkets}
        selectedInstrument={profit.selectedInstrument}
      />

      <DepositCurrencyInput
        className={`col-${Math.max(6, Math.min(12, elementSize))}`}
        updater={updateProfitCalculator}
        depositableCurrencies={profit.depositableCurrencies}
        selectedInstrument={profit.selectedInstrument}
        selectedDepositCurrency={profit.selectedDepositCurrency}
      />

      <PositionDirectionInput className={`col-${Math.max(6, Math.min(12, elementSize))}`} />

      {profit.tradeSizeInfo ? (
        <TradeSizeInput
          className={`col-${Math.min(12, elementSize * 2)}`}
          updater={updateProfitCalculator}
          unitsOfOneLot={profit.unitsOfOneLot}
          tradeSizeInfo={profit.tradeSizeInfo}
        />
      ) : null}

      <OpenPriceInput className={`col-${elementSize}`} />

      <ClosePriceInput className={`col-${elementSize}`} />

      <PriceInput
        className={`col-${elementSize}`}
        updater={updateProfitCalculator}
        priceInstrument={profit.priceInstrument}
        priceUpdatedAt={profit.priceUpdatedAt}
        price={profit.price}
      />

      <UnitsOf1Lot
        className={`col-${elementSize}`}
        updater={updateProfitCalculator}
        selectedInstrument={profit.selectedInstrument}
        unitsOfOneLot={profit.unitsOfOneLot}
        tradeSizeInfo={profit.tradeSizeInfo}
        calc="profit"
      />
    </Calculator>
  );
}

export default ProfitCalculator;
