import { ChangeEventHandler } from 'react';
import { onlyNum } from '../../../lib/funcs';
import InputGroup from '../InputGroup';
import { useLanguage } from '../../../lib/LanguageContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useDispatch } from 'react-redux';
import { updatePipvalueCalculator } from '../../../store/pipvalue/actions';

interface IPipsInput {
  className?: string;
}

function PipsInput({ className }: IPipsInput) {
  const { languageData } = useLanguage();

  const pipvalue = useSelector((state: RootState) => state.pipvalue);
  const dispatch = useDispatch();

  const pipsChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(updatePipvalueCalculator({ pips: onlyNum(e.target.value) }));
  }
  
  return (
    <div className={className || "col-12"}>
      <InputGroup type="text" label={languageData.pips} value={pipvalue.pips || ''} onChange={pipsChanged} />
    </div>
  );
}

export default PipsInput;
