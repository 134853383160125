import { LotsizeActionTypes, UPDATE_LOTSIZE_FIELDS } from "./actionTypes";
import { getLotsizeState } from "../../lib/funcs";
import { Dispatch } from "redux";

export const updateLotsizeCalculator = (updates: Partial<ILotsizeFields>): LotsizeActionTypes => ({
  type: UPDATE_LOTSIZE_FIELDS,
  payload: updates
});

export const loadInitialLotsizeFields = async (dispatch: Dispatch<LotsizeActionTypes>) => {
  try {
    let lotsizeCalculatorFields: ILotsizeFields = getLotsizeState();
    dispatch(updateLotsizeCalculator(lotsizeCalculatorFields || {}));
  } catch (error) {
    console.error('Error loading initial state from Chrome storage:', error);
  }
};