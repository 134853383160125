import { useState, useEffect, ChangeEvent } from "react";
import { useLanguage } from "../lib/LanguageContext";
import { getLanguageDirection } from "../lib/funcs";

const defaultLanguageData = require("../lib/lang/en.json");

export default function LanguageSelector() {
  let urlParams = new URLSearchParams(window.location.search);

  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const { languageData, setLanguageData } = useLanguage();

  document.body.dir = getLanguageDirection(languageData);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newSelectedLanguage = event.target.value;

    setSelectedLanguage(newSelectedLanguage);

    // If the language change is done correctly, it replaces the Lang parameter Of URL
    if (newSelectedLanguage) {
      urlParams.set("lang", newSelectedLanguage);
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${urlParams.toString()}`
      );
    }
  };
  useEffect(() => {
    setLanguageData(defaultLanguageData.en);
    let langParam = urlParams.get("lang");
    if (langParam) {
      setSelectedLanguage(langParam);
    }
    if(selectedLanguage === "en") return;
    import(`/src/lib/lang/${selectedLanguage}.json`)
      .then((data) => {
        setLanguageData(data.default[selectedLanguage]);
      })
      .catch((error) => {
        console.error(`Error loading language data: ${error}`);
      });
  }, [selectedLanguage, setLanguageData]);

  return null;
}
