export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_SAVED_FORM_INFO = 'SET_SAVED_FORM_INFO';

interface SetSessionList {
  type: typeof SET_SETTINGS;
  payload: ISettings;
}

interface SetSavedFormInfo {
  type: typeof SET_SAVED_FORM_INFO;
  payload: ISavedFormItem;
}

export type VariableActionTypes = SetSessionList | SetSavedFormInfo;