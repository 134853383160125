import { useEffect, useRef, useState } from 'react';
import styles from "./SearchableSelectBox.module.scss";
import { getLanguageDirection, stringToId } from '../../lib/funcs';
import { useLanguage } from '../../lib/LanguageContext';


function SearchableSelectBox({label, setValue, options}: { label: string, setValue: (v:string|number) => void, options: ISelectOptions[] }) {
    const [open, setOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const searchRef = useRef<HTMLInputElement>(null);
    const { languageData } = useLanguage();

    const toggleOpen = () => {
        setOpen(o => !o);
        if(!open) setSearchTerm('');
        return true;
    };

    useEffect(() => {
        const closeOnEscape = function(e: any) {
            if(e.keyCode === 27) setOpen(false);
        };
        document.addEventListener('keydown', (e) => closeOnEscape(e));
        return () => document.removeEventListener('keydown', (e) => closeOnEscape(e));
    }, []);

    useEffect(() => {
        if(open && searchRef.current) searchRef.current.focus();
    }, [open]);

    const searchResults =
        options.filter(o => !searchTerm.length ||
            o.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (o.value + '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            o.shortnote?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            o.longnote?.toLowerCase().includes(searchTerm.toLowerCase())
        );

    const listItem = (o: ISelectOptions) => {
        return <li className={o.selected ? styles.selected : ''} key={o.value + Math.random().toString(36)} onClick={() => 
            toggleOpen() && setValue(o.value)}>{
                <div className={styles.notes}>
                    <div className={styles.shortnote}>{o.name} <span>{o.note || o.shortnote || ''}</span></div>
                    <div className={styles.longnote}>{o.longnote || ''}</div>
                </div>
            }</li>;
    }

    return (
        <div className={[styles.selectBox, (open ? 'select-box-open ' + styles.open : ''), styles[getLanguageDirection(languageData)]].join(" ")}>
            <div onClick={toggleOpen} className={styles.selected}>{(options[options.findIndex(o => o.selected)] || {name: 'Please Select'}).name}</div>
            {open ? (<div className={styles.overlay} onClick={() => setOpen(false)} />) : ''}
            <div className={styles.items + ' select-box-items'}>
                {options.length > 3 ? (
                    <div className={styles.searchBox}>
                        <input ref={searchRef} type="text" className="form-control" placeholder="Search for items..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    </div>
                ) : ''}
                <ul id={stringToId(label)} className={options.length > 3 ? styles.hasSearch : ''}>
                    {searchResults.map(listItem)}
                </ul>
            </div>        
        </div>
    );
}

export default SearchableSelectBox;