import React from "react";
import styles from "./FieldSwitcher.module.scss";
import { useLanguage } from "../../lib/LanguageContext";
import { genericTranslate, getLanguageDirection } from "../../lib/funcs";

function FieldSwitcher({
  switches,
  setSwitches,
  type,
  noLine,
}: {
  noLine?: boolean;
  type?: string;
  switches: ISwitch[];
  setSwitches: (s: ISwitch[]) => void;
}) {
  const { languageData } = useLanguage();
  const currentLanguage = new URLSearchParams(window.location.search).get(
    "lang"
  );
  const isRtl = currentLanguage === "ar" || currentLanguage === "fa";

  const checkmarkStyle: React.CSSProperties = !isRtl
    ? { left: "0.4em", right: "0em" }
    : { left: "0px", right: "0.4em" };

  return (
    <div
      className={[
        styles.fieldSwitcherContainer,
        styles[getLanguageDirection(languageData)],
        noLine ? styles.noLine : "",
      ].join(" ")}
    >
      <div className={styles.fieldSwitcher}>
        {switches.map((s) => (
          <div
            key={s.label + Math.random().toString(36)}
            className={styles.switch + (s.checked ? " " + styles.selected : "")}
          >
            <label>
              <input
                type={type || "checkbox"}
                checked={s.checked}
                onChange={(e) =>
                  setSwitches(
                    switches.map((s2) =>
                      s2.label === s.label
                        ? { ...s, checked: e.target.checked }
                        : s2
                    )
                  )
                }
              />
              <span className={styles.checkmark} style={checkmarkStyle}></span>
              <span>{genericTranslate(languageData, s.label)}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FieldSwitcher;
