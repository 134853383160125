import { useState } from "react";
import styles from "./NoteContainer.module.scss";

function NoteContainer(props: any) {
  return (
    <section className={styles.noteContainer}>
      <h3>{props.title}</h3>
      {props.children}
    </section>
  );
}

export default NoteContainer;