import { toast } from 'react-toastify';

export const successMessage = (message: string) => {
    toast.success(message, {
        position: 'top-center',
        closeOnClick: true,
    });
};

export const errorMessage = (message: string) => {
    toast.error(message, {
        position: 'top-center',
        closeOnClick: true,
    });
};

export const infoMessage = (message: string) => {
    toast.info(message, {
        position: 'top-center',
        closeOnClick: true,
    });
};
