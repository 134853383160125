import { ChangeEventHandler, useEffect } from 'react';
import { getInstrumentBySymbolName, getLanguageDirection, onlyNum, stringToId } from '../../../lib/funcs';
import FormGroup from '../FormGroup';
import InnerPriceUpdater from './InnerPriceUpdater';
import { useLanguage } from '../../../lib/LanguageContext';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

interface IPriceInput {
  updater: (updates: any) => any;
  price?: string | number,
  priceUpdatedAt: number;
  priceInstrument?: { SymbolName: string, operation: string };
  className?: string;
}

function PriceInput({ price, priceInstrument, priceUpdatedAt, className, updater }: IPriceInput) {
  const dispatch = useDispatch();
  const instruments = useSelector((state: RootState) => state.instruments);

  const priceChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(updater({ price: onlyNum(e.target.value), priceUpdatedAt: new Date().getTime() }));
  }

  useEffect(() => {
    if(!priceInstrument) return;
    let prices;
    try {
        prices = JSON.parse(localStorage.getItem("prices") || "{}");
        if(priceUpdatedAt < new Date(prices.dt).getTime()) {
          const pInstrument = getInstrumentBySymbolName(priceInstrument.SymbolName);
          dispatch(updater({ 
            price: (pInstrument.SymbolRate ? Number(pInstrument.SymbolRate.toFixed(6)).toString() : ""),
            priceUpdatedAt: new Date(prices.dt).getTime()
          }));
        }
    } catch (e) {}
  }, [instruments, priceUpdatedAt, priceInstrument]);

  const { languageData } = useLanguage();

  return priceInstrument?.SymbolName ? (
      <div className={[getLanguageDirection(languageData) === "rtl" ? "text-align-right ltr" : "", className || "col-12"].join(" ")}>
        <FormGroup label={(priceInstrument.SymbolName) + ' ' + languageData.price}>
          <input type="text" id={stringToId(`${priceInstrument.SymbolName} price`)} value={price} onChange={priceChanged} />
          <InnerPriceUpdater />
        </FormGroup>
      </div>
    ) : <></>;
}

export default PriceInput;
