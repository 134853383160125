import { PipvalueActionTypes, UPDATE_PIPVALUE_FIELDS } from "./actionTypes";
import { getPipvalueState } from "../../lib/funcs";
import { Dispatch } from "redux";

export const updatePipvalueCalculator = (updates: Partial<IPipvalueFields>): PipvalueActionTypes => ({
  type: UPDATE_PIPVALUE_FIELDS,
  payload: updates
});

export const loadInitialPipvalueFields = async (dispatch: Dispatch<PipvalueActionTypes>) => {
  try {
    let pipvalueCalculatorFields: IPipvalueFields = getPipvalueState();
    dispatch(updatePipvalueCalculator(pipvalueCalculatorFields || {}));
  } catch (error) {
    console.error('Error loading initial state from Chrome storage:', error);
  }
};