import { MouseEventHandler } from "react";
import { useLanguage } from "../../../lib/LanguageContext";

interface ICalculateInput {
  calculate: MouseEventHandler<HTMLInputElement>;
  className?: string;
}

function CalculateInput({ calculate, className }: ICalculateInput) {
  const { languageData } = useLanguage();

  return (
    <div className={className || "col-12"}>
      <div className="form-group">
        <input
          type="submit"
          className="btn btn-primary btn-block"
          value={languageData.calculate || "Calculate"}
          onClick={calculate}
        />
      </div>
    </div>
  );
}

export default CalculateInput;
