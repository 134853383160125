import styles from "./ResultOutput.module.scss";

interface IResultProps {
  result: IResult;
  className?: string;
  vertical?: boolean;
  flexStart?: boolean;
}

function ResultOutput({
  result,
  className,
  vertical,
  flexStart,
}: IResultProps) {
  return result.length ? (
    <>
      {className === "col-12" ? <hr /> : null}
      <div className={className || "col-12"}>
        <div
          className={
            styles.results +
            (vertical ? " " + styles.vertical : "") +
            (flexStart ? " " + styles.flexStart : "")
          }
        >
          {result.map((r) => (
            <div key={r.name + Math.random().toString(36)} className={styles.resultItem}>
              <div className={styles.resultTitle}>{r.name}</div>
              <div className={styles.resultValue}>
                {Array.isArray(r.result)
                  ? r.result.map((res, i) => (
                      <div key={res + i.toString()}>{res}</div>
                    ))
                  : r.result}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}

export default ResultOutput;
