import React, { useEffect, useMemo, useState } from "react";
import DepositCurrencyInput from "./global/inputs/DepositCurrencyInput";
import InstrumentInput from "./global/inputs/InstrumentInput";
import StopLossInput from "./global/inputs/StopLossInput";
import BalanceInput from "./global/inputs/BalanceInput";
import RiskInput from "./global/inputs/RiskInput";
import PriceInput from "./global/inputs/PriceInput";
import Calculator from "./global/Calculator";
import UnitsOf1Lot from "./global/inputs/UnitsOf1Lot";
import OnePip from "./global/inputs/OnePip";
import { useLanguage } from "../lib/LanguageContext";
import {
  useArrangeMode,
  useElementSize,
} from "../lib/custom-hooks";
import { formatResultNum, getInstrumentBySymbolName } from "../lib/funcs";
import { DEFAULT_ONE_PIP, OPERATIONS, STOPLOSS_TYPES } from "../lib/constants";
import { useSelector } from "react-redux";
import { loadInitialLotsizeFields, updateLotsizeCalculator } from "../store/lotsize/actions";
import { RootState } from "../store/store";

function LotSizeCalculator(props: { noLine?: boolean }) {
  const { languageData } = useLanguage();
  const arrangeMode = useArrangeMode();
  const elementSize = useElementSize();

  const defaultResult = useMemo(() => [
    { name: languageData.position_size, result: "----" },
    { name: languageData.money_at_risk, result: "----" },
  ], [languageData.money_at_risk, languageData.position_size]);

  const lotsize = useSelector((state: RootState) => state.lotsize);

  const [result, setResult] = useState<IResult>(defaultResult);

  useEffect(() => {
    setResult(defaultResult);
  }, [lotsize, defaultResult]);


  const calculate: React.MouseEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    let result: string | number = "";
    if (!lotsize.selectedInstrument)
      return setResult([{ name: "Error", result: "No Instrument Selected" }]);
    if (!lotsize.selectedDepositCurrency)
      return setResult([
        { name: "Error", result: "No Deposit Currency Selected" },
      ]);
    if (!lotsize.accountBalance)
      return setResult([
        { name: "Error", result: "Please Enter Your Account Balance" },
      ]);
    if (!lotsize.stopLoss)
      return setResult([
        { name: "Error", result: "Entering StopLoss Is Required" },
      ]);
    if (!lotsize.risk)
      return setResult([
        { name: "Error", result: "Entering Risk Amount Is Required" },
      ]);
    if (!lotsize.unitsOfOneLot || !Number(lotsize.unitsOfOneLot))
      return setResult([
        {
          name: "Error",
          result: "Units of 1 lot can't be " + (lotsize.unitsOfOneLot || "empty"),
        },
      ]);
    let tickSize = Number(lotsize.onePip);

    let sl =
      STOPLOSS_TYPES.PIPS === lotsize.stopLossType
        ? Number(lotsize.stopLoss)
        : Math.abs(Number(lotsize.openPrice) - Number(lotsize.stopLoss)) / Number(lotsize.onePip);

    const moneyAtRisk =
      lotsize.riskType === "CURRENCY"
        ? Number(lotsize.risk)
        : Number(lotsize.accountBalance) * (Number(lotsize.risk) / 100);
    let r1 = moneyAtRisk / sl;
    if (lotsize.selectedInstrument.startsWith(`${lotsize.selectedDepositCurrency}/`)) {
      r1 = r1 * Number(lotsize.price);
    } else if (lotsize.selectedInstrument.endsWith(`/${lotsize.selectedDepositCurrency}`)) {
    } else {
      if (lotsize.priceInstrument?.operation === OPERATIONS.MUL) {
        r1 = r1 * Number(lotsize.price);
      } else {
        r1 = r1 / Number(lotsize.price);
      }
    }
    const selectedInstrument = getInstrumentBySymbolName(lotsize.selectedInstrument)
    const market = selectedInstrument?.Market;
    const fractionCount =
      market === "Forex" ? 0 : (lotsize.price + "").split(".")[0].length + 2;
    result = Number((r1 / tickSize / Number(lotsize.unitsOfOneLot)).toFixed(6));
    const unitSize =
      market === "Forex"
        ? Math.round(result * Number(lotsize.unitsOfOneLot))
        : result * Number(lotsize.unitsOfOneLot);
    const percentAtRisk = Number(
      (moneyAtRisk / (Number(lotsize.accountBalance) / 100)).toFixed(3)
    );

    setResult([
      {
        name: languageData.position_size,
        result: [
          Number(result.toFixed(3)) + " " + languageData.lots,
          formatResultNum(unitSize, fractionCount) + " " + languageData.units,
        ],
      },
      {
        name: languageData.money_at_risk,
        result: [
          formatResultNum(moneyAtRisk, fractionCount || 2) +
          " " +
          lotsize.selectedDepositCurrency,
          percentAtRisk + "%",
        ],
      },
    ]);
  };

  return (
    <Calculator
      updater={updateLotsizeCalculator}
      noLine={props.noLine}
      result={result}
      calculate={calculate}
      // initializer={loadInitialLotsizeFields}
    >
      <InstrumentInput
        className={`col-${Math.min(12, elementSize * 2)}`}
        updater={updateLotsizeCalculator}
        selectedMarkets={lotsize.selectedMarkets}
        selectedInstrument={lotsize.selectedInstrument}
      />

      <DepositCurrencyInput
        className={`col-${elementSize}`}
        updater={updateLotsizeCalculator}
        depositableCurrencies={lotsize.depositableCurrencies}
        selectedInstrument={lotsize.selectedInstrument}
        selectedDepositCurrency={lotsize.selectedDepositCurrency}
      />

      {arrangeMode !== 2 && arrangeMode !== 3 ? null : (
        <BalanceInput
          className={`col-${elementSize}`}
        />
      )}

      <RiskInput className={`col-${Math.min(12, elementSize * 2)}`} />

      {arrangeMode !== 2 && arrangeMode !== 3 ? (
        <BalanceInput
          className={`col-${elementSize}`}
        />
      ) : null}

      <StopLossInput />

      <PriceInput
        updater={updateLotsizeCalculator}
        className={`col-${Math.min(12, elementSize * 2)}`}
        priceInstrument={lotsize.priceInstrument}
        priceUpdatedAt={lotsize.priceUpdatedAt}
        price={lotsize.price}
      />

      <UnitsOf1Lot
        className={`col-${elementSize}`}
        updater={updateLotsizeCalculator}
        selectedInstrument={lotsize.selectedInstrument}
        unitsOfOneLot={lotsize.unitsOfOneLot}
        calc="lotsize"
      />

      <OnePip
        className={`col-${elementSize}`}
        updater={updateLotsizeCalculator}
        selectedInstrument={lotsize.selectedInstrument}
        onePip={typeof lotsize.onePip === "undefined" ? DEFAULT_ONE_PIP : lotsize.onePip}
      />
    </Calculator>
  );
}

export default LotSizeCalculator;
