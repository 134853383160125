import { getInstrumentBySymbolName, getIsMobile, getMobileAppSettings, getSavedFormInfo, saveLotUnits } from "../../lib/funcs";

type AllCalculators = ILotsizeFields | IMarginFields | IProfitFields | IPipvalueFields;

export const genLotUnits = (instrument: IInstrument, unitsOfOneLot?: string) => {
  const def = instrument.LotSize.toString() || "100000";
  const isMobile = getIsMobile(document.location);
  const settings = getMobileAppSettings();
  if (isMobile && instrument && settings.rememberChanges) {
    const savedFormInfo = getSavedFormInfo();
    return savedFormInfo[instrument.SymbolName]?.unitsOf1Lot || unitsOfOneLot || def;
  }
  return unitsOfOneLot || def;
}

export const genOnePip = (instrument: IInstrument, onePip?: string) => {
  const def = instrument.PipSize.toString() || "0.0001";
  const isMobile = getIsMobile(document.location);
  const settings = getMobileAppSettings();
  if (isMobile && instrument && settings.rememberChanges) {
    const savedFormInfo = getSavedFormInfo();
    return savedFormInfo[instrument.SymbolName]?.onePip || onePip || def;
  }
  return onePip || def;
}

export const updateUnitsOfOneLot = (changed: Partial<AllCalculators>, updated: AllCalculators) => {
  if (!updated.selectedInstrument) return updated;

  const selectedIns = getInstrumentBySymbolName(updated.selectedInstrument);
  if (typeof updated.unitsOfOneLot === "undefined") { // first render
    const lu = genLotUnits(selectedIns);
    updated.unitsOfOneLot = lu;
    updated.tradeSizeInfo = {
      base: updated.tradeSizeInfo?.base || "Lots",
      value: updated.tradeSizeInfo?.value || (updated.tradeSizeInfo?.base === "Units" ? Number(lu) : (updated.tradeSizeInfo?.value || 1)),
    }
    return updated;
  }
  if (updated.selectedInstrument) {
    let lu = updated.unitsOfOneLot;
    if (changed.hasOwnProperty("unitsOfOneLot")) {
      updated.tradeSizeInfo = {
        base: updated.tradeSizeInfo?.base || "Lots",
        value: updated.tradeSizeInfo?.value || (updated.tradeSizeInfo?.base === "Units" ? Number(updated.unitsOfOneLot) : (updated.tradeSizeInfo?.value || 1))
      };
    } else {
      lu = genLotUnits(selectedIns, updated.unitsOfOneLot);
    }
    if (changed.hasOwnProperty("selectedInstrument")) {
      lu = genLotUnits(selectedIns);
      updated.tradeSizeInfo = {
        base: updated.tradeSizeInfo?.base || "Lots",
        value: updated.tradeSizeInfo?.base === "Units" ? Number(lu) : 1,
      }
    }
    updated.unitsOfOneLot = lu;
  }

  return updated;
}

export const updateOnePips = (changed: Partial<ILotsizeFields | IPipvalueFields>, updated: ILotsizeFields | IPipvalueFields) => {
  if (!updated.selectedInstrument) return updated;

  const selectedIns = getInstrumentBySymbolName(updated.selectedInstrument);
  if (typeof updated.onePip === "undefined") {
    const op = genOnePip(selectedIns);
    updated.onePip = op;
    return updated;
  }
  if (updated.selectedInstrument) {
    let op = updated.onePip;
    if (!changed.hasOwnProperty("onePip")) {
      op = genOnePip(selectedIns, updated.onePip);
    }
    if(changed.hasOwnProperty("selectedInstrument")) {
      op = genOnePip(selectedIns);
    }
    updated.onePip = op;
  }

  return updated;
}