import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { atou, getIsMobile, getMobileAppSettings } from "./funcs";

declare global {
    interface Window { 
        fxcalc_caches: any;
        ReactNativeWebView?: any;
    }
}


const getArrangeMode = (href: string) => {
    let parts = href.split("?mode=");
    if(parts.length < 2) parts = href.split("&mode=");
    let arrangeMode = 1;
    if(parts.length > 1) {
        arrangeMode = Number(parts[1].split("&")[0]);  
    }
    if(window.outerWidth < 720 && arrangeMode === 3) {
        arrangeMode = 2;
    }
    return arrangeMode;
}

export const useArrangeMode = () => {
    const [arrangeMode, setArrangeMode] = useState(getArrangeMode(document.location.href));
    const location = useLocation();
    const [largeWidth, setLargeWidth] = useState(window.outerWidth >= 720);

    const updateArrangeMode = useCallback(() => {
        const arrangeMode = getArrangeMode(location.search);
        setArrangeMode(arrangeMode);
    }, [location]);

    useEffect(() => {
        let tmout: any;
        const updateArrangements = () => {
            if(tmout) clearTimeout(tmout);
            tmout = setTimeout(() => {
                setLargeWidth(window.outerWidth >= 720);
            }, 10);
        }

        updateArrangeMode();

        document.addEventListener("DOMContentLoaded", updateArrangements);
        window.addEventListener("resize", updateArrangements);

        return () => {
            document.removeEventListener("DOMContentLoaded", updateArrangements);
            window.removeEventListener("resize", updateArrangements);
        }
    }, [location, updateArrangeMode]);

    useEffect(() => {
        if(arrangeMode === 3) {
            if(!largeWidth) setArrangeMode(2);
        } else if(largeWidth) {
            updateArrangeMode();
        }
    }, [largeWidth, arrangeMode, updateArrangeMode]);

    return arrangeMode;
}

const getSizeByArrangeMode = (arrangeMode: number) => {
    if(arrangeMode === 2) return 6;
    if(arrangeMode === 3) return 3;
    return 12;
}

export const useElementSize = () => {
    const arrangeMode = useArrangeMode();
    const [size, setSize] = useState(getSizeByArrangeMode(arrangeMode));

    useEffect(() => {
        setSize(getSizeByArrangeMode(arrangeMode));
    }, [arrangeMode]);

    return size;
}

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);
    const location = useLocation();
    
    useEffect(() => {
        const mobile = getIsMobile(location);
        setIsMobile(mobile);
    }, [location]);

    return isMobile;
}

export const useIsPC = () => {
    const [isPC, setIsPC] = useState(false);
    const location = useLocation();
    
    useEffect(() => {
        let parts = location.search.split("?pc=");
        if(parts.length < 2) parts = location.search.split("&pc=");
        let PC = false;
        if(parts.length > 1) {
            PC = !!Number(parts[1].split("&")[0]);  
        }  
        setIsPC(PC);
    }, [location]);

    return isPC;
}

export const useShowAds = (adTypes: string[]) => {
    const [showAds, setShowAds] = useState(false);
    const location = useLocation();
    
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const adParam = queryParams.getAll('ads');

        let show = false;
        if (adParam.length > 0) {
            show = adTypes.some((adType) => adParam.includes(adType));
        }

        setShowAds(show);
    }, [location.search, adTypes]);

    return showAds;
}

export const useShowDesc = () => {
    const [showDesc, setShowDesc] = useState(false);
    const location = useLocation();
    
    useEffect(() => {
        let parts = location.search.split("?desc=");
        if(parts.length < 2) parts = location.search.split("&desc=");
        let show = false;
        if(parts.length > 1) {
            show = !!Number(parts[1].split("&")[0]);  
        }  
        setShowDesc(show);
    }, [location]);

    return showDesc;
}

export const useShowEsma = () => {
    const [showEsma, setShowEsma] = useState(false);
    const location = useLocation();
    
    useEffect(() => {
        let parts = location.search.split("?esma=");
        if(parts.length < 2) parts = location.search.split("&esma=");
        let show = false;
        if(parts.length > 1 && !show) {
            show = !!Number(parts[1].split("&")[0]);  
        }  
        setShowEsma(show);
    }, [location]);

    return showEsma;
}

export const useTitle = () => {
    const [title, setTitle] = useState("");
    const location = useLocation();
    
    useEffect(() => {
        let parts = location.search.split("?title=");
        if(parts.length < 2) parts = location.search.split("&title=");
        let t = "";
        if(parts.length > 1) {
            t = atou(parts[1].split("&")[0]);  
        }
        setTitle(t);
    }, [location]);

    return title;
}

export const useTextAlign = () => {
    const [align, setAlign] = useState<null|"right"|"left"|"center">(null);
    const location = useLocation();
    
    useEffect(() => {
        let parts = location.search.split("?align=");
        if(parts.length < 2) parts = location.search.split("&align=");
        let a = "left";
        if(parts.length > 1) {
            a = parts[1].split("&")[0];  
        }
        if(a !== "left" && a !== "right" && a !== "center") a = "left";
        setAlign(a as "right"|"left"|"center");
    }, [location]);

    return align;
}

const getSeconds = () => {
    let prices;
    try {
        prices = JSON.parse(localStorage.getItem("prices") || "{}");
        return Math.floor((new Date().getTime() - new Date(prices.dt).getTime()) / 1000);
    } catch (e) {}
    return 21;
}

export const useRemainingUpdateSeconds = () => {
    const [seconds, setSeconds] = useState(getSeconds());
    useEffect(() => {
        const intvl = setInterval(() => {
            setSeconds(getSeconds());
        }, 1000);
        return () => clearInterval(intvl);
    }, []);

    return Math.max(0, 20 - seconds);
}

