export const UPDATE_INSTRUMENTS = 'UPDATE_INSTRUMENTS';
export const UPDATE_DEPOSIT_CURRENCIES = 'UPDATE_DEPOSIT_CURRENCIES';

interface UpdateInstruments {
  type: typeof UPDATE_INSTRUMENTS;
  payload: IInstrument[];
}

interface UpdateDepositCurrencies {
  type: typeof UPDATE_DEPOSIT_CURRENCIES;
  payload: IDepositCurrency[];
}

export type InstrumentsActionTypes = UpdateInstruments;

export type DepositCurrenciesActionTypes = UpdateDepositCurrencies;