import React, {
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import CalculateInput from "./inputs/CalculateInput";
import ResultOutput from "./ResultOutput";
import {
  useElementSize,
  useIsMobile,
  useShowAds,
  useTextAlign,
  useTitle,
} from "../../lib/custom-hooks";
import { clearAllStoredInfo, getMobileAppSettings, updatePricesGlobally } from "../../lib/funcs";
import AdLinks from "./AdLinks";
import { ToastContainer } from "react-toastify";
import LanguageSelector from "../LanguageSelector";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import { loadInitialLotsizeFields, updateLotsizeCalculator } from "../../store/lotsize/actions";
import { loadInitialMarginFields, updateMarginCalculator } from "../../store/margin/actions";
import { loadInitialProfitFields, updateProfitCalculator } from "../../store/profit/actions";
import { loadInitialPipvalueFields, updatePipvalueCalculator } from "../../store/pipvalue/actions";
import { Dispatch } from "redux";

interface ICalculatorProps {
  updater: (updates: Partial<any>) => any;
  noLine?: boolean;
  children: ReactNode;
  result: IResult;
  // initializer: (dispatch: Dispatch<any>) => Promise<void>;
  calculate: MouseEventHandler<HTMLInputElement>;
}

function Calculator({
  noLine,
  children,
  result,
  calculate,
  // initializer
}: ICalculatorProps) {
  const [loading] = useState(false);
  const [wide, setWide] = useState(false);
  const elementSize = useElementSize();
  const showAds = useShowAds(['forex', 'crypto', '1']);
  const title = useTitle();
  const textAlign = useTextAlign();
  const isMobile = useIsMobile();
  const isFirstRender = useRef(true);
  const settings = useSelector((state: RootState) => state.variables.settings);
  const dispatch = useDispatch();

  useEffect(() => {
    if(isFirstRender.current) {
      setTimeout(() => {
        isFirstRender.current = false;
      }, 500);
      return;
    }
    const settings = getMobileAppSettings();
    if(!settings.rememberChanges) {
      clearAllStoredInfo(dispatch);
    }
  }, [settings.rememberChanges]);


  useEffect(() => {
    if (elementSize < 6) setWide(true);
    else setWide(false);
  }, [elementSize]);

  useEffect(() => {
    loadInitialLotsizeFields(dispatch);
    loadInitialMarginFields(dispatch);
    loadInitialPipvalueFields(dispatch);
    loadInitialProfitFields(dispatch);

    updatePricesGlobally(dispatch);
    // to get prices and everything generated
    // dispatch(updateLotsizeCalculator({}));
    // dispatch(updateMarginCalculator({}));
    // dispatch(updateProfitCalculator({}));
    // dispatch(updatePipvalueCalculator({}));
  }, []);

  useEffect(() => {
    const calcEl = document.querySelector("#calculator") as HTMLDivElement;

    const sendSizeToParent = () => {
      const href = document.location.href.split("/");
      const calcType = href[href.length - 1].split("?")[0];
      const w = calcEl.clientWidth;
      const h = calcEl.clientHeight;
      window.top?.postMessage(`IFrameSize|${calcType}|${w}X${h}`, "*");
    };

    document.addEventListener("DOMContentLoaded", sendSizeToParent, false);
    new ResizeObserver(sendSizeToParent).observe(calcEl);
    return () => {
      new ResizeObserver(sendSizeToParent).unobserve(calcEl);
      document.removeEventListener("DOMContentLoaded", sendSizeToParent, false);
    };
  }, []);

  const [windowInner, setWindowInner] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowInner(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
  });

  return (
    <>
      <LanguageSelector />
      <div
        id="calculator"
        style={{
          width: windowInner < 768 ? "100%" : (wide ? "var(--wide-calculator-width)" : "var(--calculator-width)"),
          padding: windowInner < 768 ? "auto 2rem" : "",
        }}
        className={[
          noLine ? "no-borderline" : "",
          wide ? "wide" : "",
          isMobile ? "is-mobile" : "",
        ]
          .filter((c) => c.length)
          .join(" ")}
      >
        {title ? (
          <h3 style={{ textAlign: textAlign || "left" }}>{title}</h3>
        ) : null}
        {loading ? (
          <div id="spinner-container">
            <div id="loading-spinner" />
          </div>
        ) : (
          <div className="row">
            {children}
            <hr />
            <CalculateInput
              className={`col-${Math.min(elementSize * 2, 12)}`}
              calculate={calculate}
            />
            <ResultOutput
              vertical={elementSize === 6}
              className={`col-${Math.min(elementSize * 2, 12)}`}
              result={result}
            />
            {showAds ? <AdLinks /> : ""}
          </div>
        )}
        <ToastContainer />
      </div>
    </>
  );
}

export default Calculator;
