import { useEffect, useState } from "react";
import styles from "./SettingsMenu.module.scss";
import ToggleInput from "./inputs/ToggleInput";
import TermsOfUse from "../notes/TermsOfUse";
import Disclaimer from "../notes/Disclaimer";
import { useDispatch, useSelector } from "react-redux";
import { setSettings } from "../../store/variables/varActions";
import { getMobileAppSettings } from "../../lib/funcs";
import { RootState } from "../../store/store";

function SettingsMenu() {
  const dispatch = useDispatch();
  const settings = useSelector((state: RootState) => state.variables.settings);
  const [open, setOpen] = useState(false);
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const overlayClicked = () => {
    if(showTermsOfUse) return setShowTermsOfUse(false);
    if(showDisclaimer) return setShowDisclaimer(false);
    setOpen(false);
  }

  const checkboxChanged = (e: any) => {
    setOpen(e.target.checked);
    setShowTermsOfUse(false);
    setShowDisclaimer(false);
  }

  const shareApp = () => {
    window.ReactNativeWebView?.postMessage('SHARE_APP_URL');
  }

  useEffect(() => {
    const settings = getMobileAppSettings();
    window.ReactNativeWebView?.postMessage(settings.keepScreenAwake ? 'KEEP_AWAKE_ON' : 'KEEP_AWAKE_OFF');
  }, [settings.keepScreenAwake]);

  const showMenu = !showTermsOfUse && !showDisclaimer;

  const setRememberChanges = (rememberChanges: boolean) => {
    dispatch(setSettings({
      ...settings,
      rememberChanges
    }));
  }

  const setESMALeverage = (ESMALeverage: boolean) => {
    dispatch(setSettings({
      ...settings,
      ESMALeverage
    }));
  }

  const setKeepScreenAwake = (keepScreenAwake: boolean) => {
    dispatch(setSettings({
      ...settings,
      keepScreenAwake
    }));
  }

  return (
    <>
      <div className={styles.menuToggle}>
        <input type="checkbox" checked={open} onChange={checkboxChanged} />
        
        <span></span>
        <span className={styles.midSpan}></span>
        <span></span>
        
        {showDisclaimer ? (
          <div className={styles.menu}>
            <Disclaimer />
          </div>
        ) : null}

        {showTermsOfUse ? (
          <div className={styles.menu}>
            <TermsOfUse />
          </div>
        ) : null}

        {showMenu ? (
          <ul className={styles.menu}>
            <li>
              <ToggleInput 
                onToggle={setRememberChanges} 
                options={[{ text: "Save My Changes", value: true }, { text: "Reset Data", value: false }]} 
                defaultValue={settings.rememberChanges} />
            </li>
            <li>
              <ToggleInput 
                onToggle={setKeepScreenAwake} 
                options={[{ text: "Keep Screen Awake", value: true }]} 
                defaultValue={settings.keepScreenAwake} />
            </li>
            <li>
              <ToggleInput 
                onToggle={setESMALeverage} 
                options={[{ text: "ESMA Leverage Restriction", value: true }]} 
                defaultValue={settings.ESMALeverage} />
            </li>
            <li onClick={() => setShowTermsOfUse(true)}>Terms Of Use</li>
            <li onClick={() => setShowDisclaimer(true)}>Disclaimer</li>
            <li onClick={shareApp}>Share The APP</li>
          </ul>
        ) : null}

        <div className={styles.overlay} onClick={overlayClicked}></div>
      </div>
    </>
  );
}

export default SettingsMenu;