import { ChangeEventHandler } from "react";
import { onlyNum } from "../../../lib/funcs";
import InputGroup from "../InputGroup";
import SelectGroup from "../SelectGroup";
import { useLanguage } from "../../../lib/LanguageContext";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { updateLotsizeCalculator } from "../../../store/lotsize/actions";

interface IRiskInput {
  className?: string;
}

function RiskInput({ className }: IRiskInput) {
  const { languageData } = useLanguage();
  const dispatch = useDispatch();
  const lotsize = useSelector((state: RootState) => state.lotsize);

  const correctRisk = (
    riskType: "CURRENCY" | "PERCENT",
    risk: number | string,
    balance: string | number
  ) => {
    if (!risk) return "";
    if (riskType === "PERCENT" && Number(risk) > 100) return "100";
    else if (riskType !== "PERCENT" && Number(risk) > Number(balance))
      return balance;
    return risk;
  };

  const riskChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    const risk = onlyNum(e.target.value);
    dispatch(updateLotsizeCalculator({ risk: correctRisk(lotsize.riskType, risk, lotsize.accountBalance).toString() }))
  };

  const riskTypeChanged = (value: string | number) => {
    const rtype = value as "CURRENCY" | "PERCENT";
    if (rtype === lotsize.riskType) return;

    const percentValue = Number(lotsize.accountBalance) / 100;
    let newRisk = Number((Number(lotsize.risk) * percentValue).toFixed(2));
    if (rtype === "PERCENT") {
      newRisk = Number((Number(lotsize.risk) / percentValue).toFixed(2));
    }

    dispatch(updateLotsizeCalculator({ riskType: rtype, risk: newRisk.toString() }));
  };

  return (
    <div className={className || "col-12"}>
      <div className="row">
        <div className="col-6">
          <InputGroup
            type="text"
            label={languageData.risk}
            value={correctRisk(lotsize.riskType, lotsize.risk, lotsize.accountBalance)}
            onChange={riskChanged}
          />
        </div>
        <div className="col-6">
          <SelectGroup
            label={languageData.risk_type}
            options={[
              {
                value: "PERCENT",
                name: "%",
                note: "Percent",
                selected: lotsize.riskType === "PERCENT",
              },
              {
                value: "CURRENCY",
                name: lotsize.selectedDepositCurrency || "Currency",
                note: "Currency",
                selected: lotsize.riskType === "CURRENCY",
              },
            ]}
            setValue={riskTypeChanged}
          />
        </div>
      </div>
    </div>
  );
}

export default RiskInput;
